import {
  TextField,
  IconButton,
  List,
  ListItem,
  Button,
  Box,
  CardHeader,
  Avatar,
  Divider,
  Grid,
  Card, CardContent, Skeleton, Typography, Select, InputAdornment, MenuItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Add,
  Edit,
  Garage, Save,
} from "@mui/icons-material";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {api} from "../../../services/Main/Api";
import Context from "../../../contexts/Context";
import {useSnackbar} from "notistack";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import SaveCancelButton from "../../../components/Shared/SaveCancelButton";

function DataInput(props) {
  const params = useParams()
  ///// -> When the component is called from a modal without `params` reference from url
  const { filters, dataProvider } = useContext(Context)
  const { unitId } = filters || {}
  /////
  // const { index, description, value, onChange, onRemove, id, installments, paidInstallments, createdAt, fullyPaid } = props;
  const { index, onChange, onRemove, id, getGarage, createGarage, updateGarage, deleteGarage, ...dataProps } = props;
  const { description } = dataProps
  const [handleId, setHandleId] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)
  const [edit, setEdit] = useState(false)

  const handleDescriptionChange = (event) => {
    onChange(index, { ...dataProps, description: event.target.value, id });
  };


  function disableSaveButton(){
    if (description?.length){
      return false
    } else {
      return true
    }
  }

  function handleEdit(){
    setEdit(!edit)
  }

  useEffect(function deleteObserver(){
    setOpenDelete(false)
  }, [dataProvider?.deleteGarage])


  return (
    <>

      <Box
        sx={{
          display: 'flex',
          borderRadius: '1rem',
          maxWidth: '800px',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        { id && !edit ?
          <Box
            sx={{
              justifyContent: 'space-between',
              borderRadius: '1rem',
              width: '100%',
            }}
          >

            <Box
              sx={{
                // paddingX: '2rem',
                // mt: '1rem',
                mb: '1rem'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: 'primary.main',
                      fontWeight: 'bold',
                      fontSize: '23px'
                    }}
                  >
                    {description}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ml: '1rem'

                  }}
                >
                  <IconButton onClick={() => {
                    setHandleId(id)
                    handleEdit()
                  }}>
                    <Edit />
                  </IconButton>


                  <IconButton
                    onClick={() => {
                      setHandleId(id)
                      setOpenDelete(true)
                    }}
                    sx={{padding : 0}}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            {/*<Divider sx={{mb: '1.5rem'}}/>*/}

            {/*<Box*/}
            {/*  sx={{*/}
            {/*    // paddingX: '2rem',*/}
            {/*    mb: '2rem'*/}
            {/*  }}*/}
            {/*>*/}


            {/*</Box>*/}
          </Box>

          :
          <>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                // maxWidth: '700px',
                // padding: '2rem'
              }}
            >

              { id ?
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: '0.3rem'
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                        fontSize: '23px'
                      }}
                    >
                      {description}
                    </Typography>
                  </Box>
                  <SaveCancelButton
                    edit={edit}
                    align={'start'}
                    saveClick={() => {
                      updateGarage(handleId)
                      setHandleId(null)
                      handleEdit()
                    }}
                    cancelClick={() => {
                      getGarage()
                      handleEdit()
                      setHandleId(null)
                    }}



                  />

                </Box>
                :
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography
                    variant={'h6'}
                    sx={{
                      mb: '1rem',
                      color: 'primary.main'
                    }}
                  >
                    { !id ? 'Registrar vaga' : 'Editar vaga' }
                  </Typography>
                  {!id &&
                    <SaveCancelButton
                      edit={!edit}
                      disabled={disableSaveButton()}
                      saveClick={() => {
                        createGarage(params.id ?? dataProvider?.unitId)
                      }}
                      cancelClick={() => {
                        getGarage()
                        setHandleId(null)
                      }}

                    />

                  }


                </Box>


              }

              <TextField
                label="Vaga"
                size={'small'}
                disabled={id && handleId !== id}
                value={description}
                onChange={handleDescriptionChange}
                sx={{
                  width: '100%',
                  mb: '1rem'

                }}
              />


            </Box>

          </>
        }
        <SimpleDialog
          open={openDelete}
          setOpen={setOpenDelete}
          title={'Excluir vaga'}
          question={'Realmente quer excluir essa vaga?'}
          onClickConfirm={() => deleteGarage(handleId)}
        />
      </Box>
    </>
  );
}

function GarageFormSet(props) {
  const params = useParams()
  const { id } = params
  const { dataProvider, setDataProvider } = useContext(Context)

  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(!!dataProvider?.unitId ?? !!params?.id)


  const {enqueueSnackbar} = useSnackbar()


  const handleDataChange = (index, newData) => {
    const newDataList = [...data];
    newDataList[index] = newData;
    setData(newDataList);
  };

  const handleAddData = () => {
    const newDataList = [...data, { description: '' }];
    setData(newDataList);
  };

  async function getGarage (){
    try {
      const response = await api.get(`garage/?unit=${dataProvider?.unitId ?? params?.id}`);
      setData(response.data.results);
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(function mainLoad(){
    setIsLoading(true)
    getGarage()
  },[])


  async function createGarage() {
    const dataReq = data.map((garage) => {
      return {
        ...garage,
        unit: dataProvider?.unitId ?? params?.id,
      };
    });

    for (const garage of dataReq) {
      if (!garage?.id) {
        try {
          const response = await api.post(`garage/`, {
            description: garage.description,
            unit: dataProvider?.unitId ?? params?.id,
          });
          getGarage();
          enqueueSnackbar('Vaga registrada', { variant: 'success' });
        } catch (error) {
          enqueueSnackbar('Erro ao registrar vaga', { variant: 'error' });
          console.log(error);
        }
      }
    }
  }


  async function updateGarage(garageId) {
    const updateGarage = data.find((garage) => garage.id === garageId);
    if (updateGarage) {
      try {
        const response = await api.put(`garage/${updateGarage.id}/`, {
          description: updateGarage.description,
          unit: dataProvider?.unitId ?? params?.id,
        });
        enqueueSnackbar('Garagem atualizada', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Erro ao atualizar garagem', { variant: 'error' });
        console.log(error);
      } finally {
        getGarage();
      }
    }
  }

  async function deleteGarage(garageId) {
    try {
      await api.delete(`garage/${garageId}/`);
      enqueueSnackbar('Vaga deletada', { variant: 'success' });
      setDataProvider(prevState => ({ deleteGarage: !dataProvider?.deleteGarage }))
      await getGarage();
    } catch (error) {
      enqueueSnackbar('Erro ao excluir vaga', { variant: 'error' });
      console.log(error);
    }
  }

  return (
    <Grid item xs={12}>
      {!isLoading ?
        <Card>
          <CardHeader
            avatar={<Avatar sx={{bgcolor: 'primary.main'}}><Garage  /></Avatar>}

            title={'Garagem'}/>
          <Divider sx={{mb: '.3rem'}}/>

          <CardContent>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <List>
                {data?.map((entry, index) => (
                  <ListItem key={index} disableGutters>
                    <DataInput
                      index={index}
                      id={entry.id}
                      description={entry.description}
                      onChange={handleDataChange}
                      getGarage={getGarage}
                      createGarage={createGarage}
                      updateGarage={updateGarage}
                      deleteGarage={deleteGarage}
                    />
                  </ListItem>
                ))}
              </List>
              <Button
                size={'small'}
                variant="contained"
                startIcon={<Add/>}
                onClick={handleAddData}>
                Adicionar
              </Button>
            </Box>
          </CardContent>
        </Card>
        :
        <Skeleton
          height={300}
          width={'100%'}
          variant='rounded'
        />
      }
    </Grid>
  );
}

export default GarageFormSet