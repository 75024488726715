import React, {useContext} from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton, MenuItem, Skeleton,
  TextField,
  Typography
} from "@mui/material";
import {
  Delete,
  Edit,
  Lock
} from "@mui/icons-material";
import CredPagoInputs from "./CredPagoInputs";
import CredPagoRead from "./CredPagoRead";
import GuarantorInputs from "./GuarantorInputs";
import GuarantorRead from "./GuarantorRead";
import BailInsuranceInpunts from "./BailInsuranceInpunts";
import BailInsuranceRead from "./BailInsuranceRead";
import CapitalizationInputs from "./CapitalizationInputs";
import CapitalizationRead from "./CapitalizationRead";
import SimpleDialog from "../../../../components/Shared/SimpleDialog";
import Context from "../../../../contexts/Context";
import EscrowInputs from "./EscrowInputs";
import EscrowRead from "./EscrowRead";


const GuaranteeCard = ({
     isLoading,
     guaranteeData,
     setGuaranteeData,
     edit,
     setEdit,
     update,
     guaranteSave,
     getContract,
     openDelete,
     setOpenDelete,
     deleteGuarantee,
     guarantee,
     setGuarantee,
   }) => {

  const { dataProvider, setDataProvider } = useContext(Context)


  return (
    <>
      {!isLoading && !dataProvider?.skelWarranty ?
        <Card>
          <CardHeader
            avatar={
              <Avatar
                sx={{
                  bgcolor: 'primary.main'
                }}>
                <Lock />
              </Avatar>}
            title={'Garantias'}
          />
          <Divider sx={{ mb: '.3rem' }}/>

          <CardContent>
            <Box
              sx={{
                // mb: '1rem',
                borderRadius: '1rem',
              }}
            >

              {!guaranteeData?.id && !edit &&
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <Button
                    size={'small'}
                    variant={'contained'}
                    onClick={() => {
                      setEdit(true)
                    }}
                  >
                    + Adicionar
                  </Button>
                </Box>
              }
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  // mb: '1rem'
                }}
              >
                {guaranteeData?.id ?
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '18px',
                      color: 'primary.main',
                    }}
                  >
                    {guarantee === 'credpago' && 'CredPago'}
                    {guarantee === 'guarantor' && 'Fiador'}
                    {guarantee === 'bail_insurance' && 'Seguro Fiança'}
                    {guarantee === 'titulo_de_captalização' && 'Título de Capitalização'}
                    {guarantee === 'deposit' && 'Caução'}

                  </Typography>
                  :
                  <Box>
                  </Box>
                }
                <Box>
                  {guaranteeData?.id && !edit &&
                    <IconButton
                      onClick={() => setEdit(true)}
                    >
                      <Edit/>
                    </IconButton>
                  }
                  {edit &&
                    <Box
                      sx={{
                        mb: '0.6rem'
                      }}
                    >
                      <Button
                        size={'small'}
                        variant={'contained'}
                        onClick={() => {
                          update(true)
                          !guaranteeData?.id && setDataProvider(prevState => ({...prevState, skelWarranty: true}))
                        }}
                        disabled={!guaranteSave}
                      >
                        Salvar
                      </Button>
                      <Button
                        size={'small'}
                        onClick={() => {
                          getContract(null, true)
                          setEdit(false)
                        }}
                      >
                        Cancelar
                      </Button>
                    </Box>
                  }

                  {guaranteeData?.id && !edit &&
                    <IconButton
                      sx={{ p: 0}}
                      onClick={() => setOpenDelete(true)}
                    >
                      <Delete/>
                    </IconButton>
                  }



                </Box>

              </Box>
              {!guaranteeData?.id && edit &&
                <Box>
                  <TextField
                    select
                    label={'Tipo'}
                    value={guarantee ?? ''}
                    size={'small'}
                    onChange={(e) => {
                      setGuarantee(e.target.value)
                      setGuaranteeData(null)
                    }}
                    sx={{mb: '1rem', width: '100%'}}
                  >
                    <MenuItem value="deposit"> Caução</MenuItem>
                    <MenuItem value="credpago">CredPago</MenuItem>
                    <MenuItem value="guarantor">Fiador</MenuItem>
                    <MenuItem value="bail_insurance">Seguro Fiança</MenuItem>
                    <MenuItem value="titulo_de_captalização"> Título de Capitalização</MenuItem>

                  </TextField>
                </Box>
              }

              <CredPagoInputs
                edit={edit}
                guarantee={guarantee}
                guaranteeData={guaranteeData}
                setGuaranteeData={setGuaranteeData}
              />

              <CredPagoRead
                edit={edit}
                guarantee={guarantee}
                guaranteeData={guaranteeData}
              />

              <GuarantorInputs
                edit={edit}
                guarantee={guarantee}
                guaranteeData={guaranteeData}
                setGuaranteeData={setGuaranteeData}
              />

              <GuarantorRead
                edit={edit}
                guarantee={guarantee}
                guaranteeData={guaranteeData}
              />

              <BailInsuranceInpunts
                edit={edit}
                guarantee={guarantee}
                guaranteeData={guaranteeData}
                setGuaranteeData={setGuaranteeData}
              />

              <BailInsuranceRead
                edit={edit}
                guarantee={guarantee}
                guaranteeData={guaranteeData}
              />

              <CapitalizationInputs
                edit={edit}
                guarantee={guarantee}
                guaranteeData={guaranteeData}
                setGuaranteeData={setGuaranteeData}
              />

              <CapitalizationRead
                edit={edit}
                guarantee={guarantee}
                guaranteeData={guaranteeData}
              />

              <EscrowInputs
                edit={edit}
                guarantee={guarantee}
                guaranteeData={guaranteeData}
                setGuaranteeData={setGuaranteeData}
              />

              <EscrowRead
                edit={edit}
                guarantee={guarantee}
                guaranteeData={guaranteeData}
              />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >

              </Box>
              <SimpleDialog
                open={openDelete}
                setOpen={setOpenDelete}
                title={'Excluir garantia'}
                question={'Realmente quer excluir essa garantia?'}
                onClickConfirm={()=> {
                  deleteGuarantee()
                  setDataProvider(prevState => ({...prevState, skelWarranty: true}))
                }}
              />

            </Box>
          </CardContent>
        </Card>
        :
        <Skeleton
          height={400}
          width={'100%'}
          variant='rounded'
        />
      }

    </>
  )
}

export default GuaranteeCard