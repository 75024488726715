import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {InputAdornment, TextField, useMediaQuery} from "@mui/material";
import SimpleSelector from "../../../../components/Shared/SimpleSelector";
import SimpleText from "../../../../components/Shared/SimpleText";
import {Percent} from "@mui/icons-material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import React from "react";
import {useParams} from "react-router-dom";

const ContractInputs = (
  {
    reqData,
    setReqData,
    contractTypeOptions,
    contractReadjustmentIndex,
    adminTaxPreview,
    intermediationPreview,
    error,
  }) => {
  const params = useParams()

  const sizeMatch = useMediaQuery('@media (min-width:730px)')


  return (
    <>
      <DatePicker
        label={'Data de Início'}
        value={moment(reqData?.start_date).format() ?? ''}
        onChange={(newValue) => setReqData((prevState) => ({
          ...prevState,
          start_date: newValue
        }))}
        renderInput={(params) =>
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              readOnly: true,
              placeholder:"dd/mm/aaaa",
            }}
            required
            size={"small"}
            sx={{
              mb: '1rem',
              width: '100%'
              // width: reqData?.unit && sizeMatch ? '300px' : 'auto'
            }}

          />}
      />

      <SimpleSelector
        label={'Tipo de Contrato'}
        onlyNumbers
        state={reqData}
        setState={setReqData}
        keyName={'contract_type'}
        simpleOptions={contractTypeOptions}
        styles={{
          mb: '1rem',
          width: '100%'
          // maxWidth: reqData?.unit && sizeMatch ? '300px' : 'auto',

        }}
      />

      <SimpleText
        label={'Tempo de Contrato'}
        state={reqData}
        setState={setReqData}
        keyName={'contract_time'}
        error={error}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              meses
            </InputAdornment>
          )
        }}
        styles={{
          mb : '1rem',
          width: '100%',
          // maxWidth: reqData?.unit && sizeMatch ? '300px' : 'auto',

        }}

      />

      <SimpleText
        label={'Taxa Administrativa'}
        float
        required
        directHelper={error?.administrative_tax ?? adminTaxPreview()}
        onChange={(e) => setReqData(prevState => ({...prevState, administrative_tax: e.target.value?.slice(0,10)}))}
        state={reqData}
        // setState={setReqData}
        keyName={'administrative_tax'}
        error={error}
        styles={{
          width: '100%',
          // maxWidth: reqData?.unit && sizeMatch ? '300px' : 'auto',
          mb: '1rem',
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Percent />
            </InputAdornment>
          )
        }}
      />

      <SimpleText
        label={'Taxa de Intermediação'}
        float
        directHelper={error?.intermediation_fee ?? intermediationPreview()}
        state={reqData}
        onChange={(e) => setReqData(prevState => ({...prevState, intermediation_fee: e.target.value?.slice(0,10)}))}
        keyName={'intermediation_fee'}
        error={error}
        styles={{
          width: '100%',
          // maxWidth: reqData?.unit && sizeMatch ? '300px' : 'auto',
          mb: '1rem',
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Percent />
            </InputAdornment>
          )
        }}
      />

        <SimpleSelector
            label={'Indice para Reajuste'}
            state={reqData}
            setState={setReqData}
            keyName={'readjustment_index'}
            simpleIndexOptions={contractReadjustmentIndex}
            styles={{
                mb: '1rem',
                width: '100%'
                // maxWidth: reqData?.unit && sizeMatch ? '300px' : 'auto',

            }}
        />

      <SimpleText
        label={'Valor'}
        money
        required
        state={reqData}
        setState={setReqData}
        keyName={'value'}
        error={error}
        styles={{
          // maxWidth: reqData?.unit && sizeMatch ? '300px' : 'auto',
          width: '100%',
          mb: '1rem',
        }}
      />


      {params?.id && reqData?.status !== 'pending' &&
        <ToggleButtonGroup
          fullWidth
          size={'small'}
          exclusive
          value={reqData?.active}
          color={'primary'}
          onChange={(e, value) => setReqData(prevState => ({
            ...prevState,
            status: value
          }))}
          sx={{
            // maxWidth: reqData?.unit && sizeMatch ? '300px' : '300px'
            width: '100%',

          }}
        >
          <ToggleButton
            value={'inactive'}
            selected={reqData?.status === 'inactive'}
          >
            Encerrado
          </ToggleButton>
          <ToggleButton
            value={'active'}
            selected={reqData?.status === 'active'}
            sx={{
              '&.Mui-selected': {
                color: '#3d3d3d',
                backgroundColor: 'rgba(0,255,0,0.38)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 255, 0, 0.7)',
                },
              },
            }}
          >
            Ativo
          </ToggleButton>
        </ToggleButtonGroup>
      }

    </>
  )
}

export default ContractInputs