import DialogTitle from "@mui/material/DialogTitle";
import {Alert, Button, Dialog, Divider, FormControlLabel, Switch, TextField} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers'
import moment from "moment";
import * as React from "react";
import DialogActions from "@mui/material/DialogActions";
import {useMediaQuery} from "@mui/material";
import { useState } from 'react';

const MakeBankSlip = ({ openByStatus, setOpenByStatus, reqCreateInvoice, setReqCreateInvoice, handleCloseConfirmation, generate, errors, invoice}) => {
  const sizeMatch = useMediaQuery('@media (min-width:600px)')
    const [errorMsg, setErrorMsg] = useState("");

  return (
    <Dialog
      fullScreen={!sizeMatch}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '600px', maxHeight: 435, padding: 2 } }}
      // sx={{
      //   '& .MuiDialog-paper': { width: '80%', maxHeight: 435, padding: 2  }
      // }}
      maxWidth="xs"
      open={openByStatus.create ?? !!openByStatus.create}
      onClick={event => event.stopPropagation()}
    >
      <DialogTitle >{"Gerar Boleto"}</DialogTitle>
      <Divider />
      <DialogContent >
        {"Para onde deseja enviar o boleto?"}
      </DialogContent>
      <FormControlLabel
        label={"Enviar por e-mail?"}
        sx={{
          pl: 2,
          pr: 2
        }}
        control={

          <Switch
            checked={reqCreateInvoice.send_email}
            onChange={(e) => {
              setReqCreateInvoice((prevState) => ({...prevState, send_email: !reqCreateInvoice.send_email}))
            }}
            name="Enviar por e-mail"/>}
      />
      <FormControlLabel
        sx={{pl: 2, pr: 2}}
        control={
          <Switch
              disabled={!invoice?.whatsapp_send_message}
            checked={reqCreateInvoice.send_whatsapp}
            onChange={(e) => {
              setReqCreateInvoice((prevState) => ({...prevState , send_whatsapp: !reqCreateInvoice.send_whatsapp}))
            }}
            name="Enviar por whatsapp"/>}
        label={"Enviar por Whatsapp?"}
      />
      <Divider sx={{ marginY: '1rem' }}/>

        <MobileDatePicker
            label="Data de Vencimento"
            value={moment(reqCreateInvoice.due_date).format()}
            minDate={new Date(moment().format())}
            disableHighlightToday
            onChange={(e) => {
                const selectedDate = moment(e);
                const today = moment().startOf('day');

                if(selectedDate.isSameOrBefore(today)) {
                    setErrorMsg(<Alert severity="info">"QRCode Pix não é possivel gerar para o mesmo dia, Para gerar o QRCode Pix é necessário alterar a data para o próximo dia."</Alert>);
                } else {
                    setErrorMsg("");
                }

                setReqCreateInvoice((prevState) => ({...prevState,due_date: selectedDate.format('YYYY-MM-DD')}));
            }}
            renderInput={(params) => <TextField
                {...params}
                fullWidth
                inputProps={{
                    ...params.inputProps,
                    placeholder:'dd/mm/aaaa'
                }}
                error={!!errors || !!errorMsg}
                helperText={errorMsg}
            />}
        />
      <DialogActions
        sx={{mt: 2}}
      >
        <Button autoFocus onClick={handleCloseConfirmation} >{"Cancelar"}</Button>
        <Button variant={"contained"}
                disabled={!reqCreateInvoice?.due_date}
                onClick={(e) => {
          e.stopPropagation()
          setOpenByStatus(prevState => ({...prevState, create: false}))
          generate()
        }}>{"Confirmar"}</Button>
      </DialogActions>

    </Dialog>
  )
}

export default MakeBankSlip