import React, {
  useEffect,
  useState
} from "react";
import moment from "moment";
import {
  Box,
  TextField
} from "@mui/material";
import Masks from "../../../../../components/Shared/Masks";
import DateAndTime from "../../../../../components/BookingAndMaintenance/DateAndTime";

const TransferInfo = ({ reqData, setReqData }) => {
  const [timepicker, setTimePicker] = useState(moment().locale('pt-br').format("LT"))

  function dateFormat () {
    const formEndDate = moment(reqData?.payment_date).format('YYYY-MM-DD')
    const setEndDate = formEndDate + ' ' + timepicker
    return moment(setEndDate).format()
  }

  useEffect(function whenClockChange(){
    setReqData(prevState => ({...prevState, payment_date: dateFormat() }))
  }, [reqData?.payment_date, timepicker])


  return (
    <Box>
      <TextField
        label="Valor"
        name="transferValue"
        type="text"
        size={'small'}
        fullWidth
        required
        value={Masks.money(reqData?.value ?? '')}
        onChange={({ target: { value } }) => setReqData((prevState) => ({
          ...prevState,
          value: value
        }))}
        sx={{ mb: 2 }}
      />

      <DateAndTime
        labelDate={'Data de Transferência'}
        labelTime={'Horário'}
        minDate={moment().format}
        setState={setReqData}
        valueFunction={dateFormat}
        timePicker={setTimePicker}
        keyName={'payment_date'}
      />



    </Box>
  )
}

export default TransferInfo