import DialogTitle from "@mui/material/DialogTitle";
import {Alert, Button, Dialog, Divider, FormControlLabel, Switch, TextField} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import {DesktopDatePicker, MobileDatePicker} from "@mui/x-date-pickers";
import moment from "moment/moment";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import {useMediaQuery} from "@mui/material";
import {useState} from "react";

const MakeDuplicate = ({ openByStatus, setOpenByStatus, reqNewInvoice, setReqNewInvoice, handleCloseConfirmation, generateNew, errors, invoice }) => {


  const sizeMatch = useMediaQuery('@media (min-width:600px)')
    const [errorMsg, setErrorMsg] = useState("");


    return (
    <Dialog
      fullScreen={!sizeMatch}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '600px', maxHeight: 435, padding: 2 } }}
      maxWidth="xs"
      open={openByStatus.remake  ?? !!openByStatus.remake}
      onClick={event => event.stopPropagation()}
    >
      <DialogTitle>
        {"Segunda Via"}
      </DialogTitle>

      <Divider />

      <DialogContent >
        {"Para onde deseja enviar a segunda via?"}
      </DialogContent>

      <FormControlLabel
        label={"Enviar por e-mail?"}
        sx={{
          pl: 2,
          pr: 2
        }}
        control={
          <Switch
            checked={reqNewInvoice.send_email}
            onChange={(e) => {
              setReqNewInvoice((prevState) => ({...prevState, send_email: !reqNewInvoice.send_email}))
            }}
            name="Enviar por e-mail"/>}
      />
      <FormControlLabel
        sx={{pl: 2, pr: 2}}
        control={
          <Switch
              disabled={!invoice?.whatsapp_send_message}
              checked={reqNewInvoice.send_whatsapp}
              onChange={(e) => {
              setReqNewInvoice((prevState) => ({...prevState , send_whatsapp: !reqNewInvoice.send_whatsapp}))
            }}
            name="Enviar por whatsapp"
          />}
        label={"Enviar por Whatsapp?"}
      />
      <Divider
        sx={{
          marginY: '1rem'
        }}
      />

      {/*<DesktopDatePicker*/}
      {/*  label="Nova Data de Vencimento"*/}
      {/*  value={new Date(moment(reqNewInvoice.due_date))}*/}
      {/*  minDate={new Date(moment().format())}*/}
      {/*  disableHighlightToday*/}
      {/*  onChange={(e) => {*/}
      {/*    setReqNewInvoice((prevState) => ({...prevState,due_date: moment(e).format('YYYY-MM-DD')}))*/}
      {/*  }}*/}
      {/*  renderInput={(params) => <TextField*/}
      {/*    {...params}*/}
      {/*    inputProps={{*/}
      {/*      ...params.inputProps,*/}
      {/*      placeholder:'dd/mm/aaaa'*/}
      {/*    }}*/}
      {/*    fullWidth*/}
      {/*    error={!!errors}*/}
      {/*  />}*/}
      {/*/>*/}
        <MobileDatePicker
            label="Nova Data de Vencimento"
            value={moment(reqNewInvoice.due_date).format()}
            minDate={new Date(moment().format())}
            disableHighlightToday
            onChange={(e) => {
                const selectedDate = moment(e);
                const today = moment().startOf('day');

                if(selectedDate.isSameOrBefore(today)) {
                    setErrorMsg(<Alert severity="info">"QRCode Pix não é possivel gerar para o mesmo dia, Para gerar o QRCode Pix é necessário alterar a data para o próximo dia."</Alert>);
                } else {
                    setErrorMsg("");
                }

                setReqNewInvoice((prevState) => ({...prevState,due_date: selectedDate.format('YYYY-MM-DD')}));
            }}
            renderInput={(params) => <TextField
                {...params}
                fullWidth
                inputProps={{
                    ...params.inputProps,
                    placeholder:'dd/mm/aaaa'
                }}
                error={!!errors || !!errorMsg}
                helperText={errorMsg}
            />}
        />

      <DialogActions
        sx={{mt: 2}}
      >
        <Button autoFocus onClick={handleCloseConfirmation} >{"Cancelar"}</Button>
          <Button variant={"contained"} onClick={(e) => {
              e.stopPropagation()
              setOpenByStatus(prevState => ({...prevState, remake: false}))
              generateNew()
          }}>{"Confirmar"}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default MakeDuplicate