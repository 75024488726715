import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Box, TextField} from "@mui/material";
import {TimePicker} from "@mui/x-date-pickers";
import moment from "moment";
import React from "react";

const DateAndTime = ({
    labelDate,
    labelTime,
    setState,
    valueFunction,
    timePicker,
    disabled,
    styles,
    keyName,
    required,
    disabledDate,
    minDate
  }) => {

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: '1rem',
        ...styles,
      }}
    >
      <DatePicker
        disabled={disabledDate}
        minDate={minDate}
        label={labelDate}
        value={valueFunction() ?? ''}
        inputProps={{
          placeholder: 'DD/MM/AAAA',
          readOnly: true
        }}
        onChange={(newValue) => {
          setState((prevState) => ({
            ...prevState,
            [keyName]: newValue
          }))
        }}
        renderInput={(params) =>
          <TextField {...params}
                     disabled={disabled}
                     required={required}
                     size={"small"}
                     sx={{
                       width: '49%',
                     }}
          />}
      />
      <TimePicker
        label={labelTime}
        value={valueFunction() ?? ''}
        onChange={(newValue) => {
          timePicker(moment(new Date(newValue)).locale('pt-br').format("LT"))
          setState((prevState) => ({
            ...prevState,
            [keyName]: newValue
          }))
        }}
        renderInput={(params) =>
          <TextField
            {...params}
            disabled={disabled}
            required={required}
            size='small'
            sx={{
              width: '49%'
            }}

          />}
      />

    </Box>
  )
}

export default DateAndTime