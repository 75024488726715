import {Box, Button, IconButton} from "@mui/material";
import {Edit} from "@mui/icons-material";
import React from "react";

const SaveCancelButton = ({edit, disabled, saveClick, cancelClick, align}) => {
  return (
    <>

      {edit &&
        <Box
          sx={{
            minHeight: '49.154px',
            display:'flex',
            alignItems: align ?? 'center'
          }}
        >
          <Button
            size={'small'}
            disabled={disabled}
            variant={'contained'}
            sx={{
              mr: '0.5rem'
            }}
            onClick={saveClick}
          >
            Salvar
          </Button>
          <Button
            size={'small'}
            onClick={cancelClick}
          >
            Cancelar
          </Button>
        </Box>
      }

    </>
  )
}

export default SaveCancelButton