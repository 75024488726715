import SimpleTypography from "../../../../../components/Shared/SimpleTypography";
import React from "react";
import {useParams} from "react-router-dom";
import {Box} from "@mui/system";
import {Close} from "@mui/icons-material";

const UnitRead =
  (
    {
      reqData,
      loadBlock,
      rentUrl,

    }
  ) => {

  const params = useParams()

  function typeFormat(param){
    switch (param) {
      case 'apartment':
        return 'Apartamento';
      case 'house':
        return 'Casa';
      case 'commercial_house':
        return 'Casa comercial';
      case 'pavilion':
        return 'Pavilhão';
      case 'commercial_room':
        return 'Sala comercial';
      case 'batch':
        return 'Lote';
      default:
        return '- - -';
    }
  }

  return (
    <Box
    >
      <SimpleTypography
        label={`Tipo`}
        directValue={typeFormat(reqData?.unit_type) ?? <Close sx={{color: 'error.main'}}/>}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'
        }}
      />

      <SimpleTypography
        label={`Nome`}
        directValue={reqData?.name ?? <Close sx={{color: 'error.main'}} />}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'
        }}
      />

      <SimpleTypography
        label={'CPF / CNPJ do Morador'}
        directValue={reqData?.cpf ?? <Close sx={{color: 'error.main'}} />}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'
        }}
      />

      <SimpleTypography
        label={'E-mail'}
        directValue={reqData?.email?.length > 0 ? reqData?.email : <Close sx={{color: 'error.main'}} />}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'
        }}
      />

      <SimpleTypography
        label={'Telefone'}
        directValue={reqData?.phone ?? <Close sx={{color: 'error.main'}} />}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'
        }}
      />

      <SimpleTypography
        label={'Rua'}
        directValue={reqData?.street ?? <Close sx={{color: 'error.main'}} />}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'
        }}
      />

      <SimpleTypography
        label={'Número'}
        directValue={reqData?.number ?? <Close sx={{color: 'error.main'}} />}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'
        }}
      />

      {rentUrl &&
        <>
          <SimpleTypography
            label={'Bairro'}
            directValue={reqData?.district ?? <Close sx={{color: 'error.main'}} />}
            labelStyles={{
              fontWeight: 'bold'
            }}
            boxStyles={{
              width: '100%',
              mb: '0.5rem'
            }}
          />

          <SimpleTypography
            label={'Complemento'}
            directValue={reqData?.complement ?? <Close sx={{color: 'error.main'}} />}
            labelStyles={{
              fontWeight: 'bold'
            }}
            boxStyles={{
              width: '100%',
              mb: '0.5rem'
            }}
          />

          <SimpleTypography
            label={'Cidade'}
            directValue={reqData?.city ?? <Close sx={{color: 'error.main'}} />}
            labelStyles={{
              fontWeight: 'bold'
            }}
            boxStyles={{
              width: '100%',
              mb: '0.5rem'
            }}
          />

          <SimpleTypography
            label={'Estado'}
            directValue={reqData?.state ?? <Close sx={{color: 'error.main'}} />}
            labelStyles={{
              fontWeight: 'bold'
            }}
            boxStyles={{
              width: '100%',
              mb: '0.5rem'
            }}
          />

          <SimpleTypography
            label={'CEP'}
            directValue={reqData?.cep ?? <Close sx={{color: 'error.main'}} />}
            labelStyles={{
              fontWeight: 'bold'
            }}
            boxStyles={{
              width: '100%',
              mb: '0.5rem'
            }}
          />
        </>
      }

      <SimpleTypography
        label={'m²'}
        directValue={reqData?.square_meter ?? <Close sx={{color: 'error.main'}} />}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'
        }}
      />

      <SimpleTypography
        label={'Área Construída m²'}
        directValue={reqData?.building_meter ?? <Close sx={{color: 'error.main'}} />}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'
        }}
      />

      {loadBlock === true && params?.id &&
        <SimpleTypography
          label={'Blocos'}
          directValue={reqData?.block ?? <Close sx={{color: 'error.main'}} />}
          labelStyles={{
            fontWeight: 'bold'
          }}
          boxStyles={{
            width: '100%',
            mb: '0.5rem'
          }}
        />
      }

    </Box>
  )
}

export default UnitRead