import React, {useContext, useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {api} from '../../../services/Main/Api'
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  Container, Skeleton, useMediaQuery,
} from '@mui/material'
import {getCondoId} from '../../../services/Main/AuthStorage'
import InternalNotesCard from '../../../components/Shared/InternalNotesCard'
import {Helmet} from 'react-helmet'
import {useSnackbar} from "notistack";
import InvoiceDetailsButtons from "./components/InvoiceDetail/InvoiceDetailsButtons";
import InvoiceDetailHeader from "./components/InvoiceDetail/InvoiceDetailHeader/InvoiceDetailHeader";
import InvoiceDetailBody from "./components/InvoiceDetail/InvoiceDetailBody/InvoiceDetailBody";
import InvoiceDetailTable from "./components/InvoiceDetail/InvoiceDetailTable/InvoiceDetailTable";
import InvoiceDetailed from "./components/InvoiceDetail/InvoiceDetailed.";
import SimpleButtons from "../../../components/Shared/SimpleButtons";
import AnimationGlobal from "../../../components/Shared/animations/AnimationGlobal";
import loadingAnimation from '../../../assets/animations/loading.json'
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import Context from "../../../contexts/Context";
import CircularProgress from "@mui/material/CircularProgress";

const InvoiceDetail = () => {
  const { filters, setFilters, loadingModal, setLoadingModal } = useContext(Context)

  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [invoice, setInvoice] = useState()
  const [condo, setCondo] = useState()
  const [routeId, setRouteId] = useState('')
  const [sending,  setSending] = useState(false)

  const sizeMatch = useMediaQuery('@media (min-width:600px)')

  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()

  useEffect(() => {
    setIsLoading(true)
    getInvoiceId()
    getCondo()
    window.scrollTo(0, 0)

    setTimeout(() =>{
    setIsLoading(false)
    }, 1000)
  }, [params?.id])

  const getInvoiceId = async () => {
    const url = `invoice/${!filters?.invoiceId ? params.id : filters?.invoiceId}/`
    let result = await api.get(url)
    result = await result.data
    setInvoice(result)
  }

  const getCondo = async () => {
    const condo_id = getCondoId()
    const url = `condo/${condo_id}/`
    let result = await api.get(url)
    result = await result.data

    setCondo(result)
  }

  useEffect(function changeRoute(){
    if (routeId){
    navigate(`/faturas/${routeId}`)
    }
  }, [routeId])


  const sendToMail = () => {
    setSending(true)
    setLoadingModal(true)
    api.post(`/bank-slip/invoice/${invoice?.id}/send-mail/`)
      .then(r => {
        enqueueSnackbar(r.data.success, {variant: "success"})
      })
      .catch(error => {
        console.log(error)
        enqueueSnackbar('Erro ao Enviar Para o E-mail', {variant: "error"})
      }).finally(() =>{
        setSending(false)
        setLoadingModal(false)
    })

  }

  return (
    <>
      {!filters?.modalInvoice ?
      <>
      <Helmet>
        <title>Detalhes da Fatura - Bloco B3</title>
      </Helmet>
        <Backdrop
          sx={{ color: '#fff', zIndex: 1500, }}
          open={loadingModal}
        >
          <CircularProgress color="inherit"/>
        </Backdrop>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >

        <Box
          sx={{
            display: 'grid',
            justifySelf: 'center',
            alignItems: 'center',
            width: sizeMatch ? '100%' : 'auto',
            marginX: sizeMatch ?  '2rem' : '0.5rem'

          }}
        >
        {/*{!isLoading &&*/}
          <Card
            sx={{
              width: '100%',
              // minWidth: '400px'
            }}

          >
          { !isLoading ?
          <CardContent>
              <>
                <InvoiceDetailHeader
                  invoice={invoice}
                  condo={condo}
                  sending={sending}
                  sendToMail={sendToMail}
                />
                <InvoiceDetailBody
                  condo={condo}
                  invoice={invoice}
                />
                {sizeMatch ?
                  <Box
                  >
                    <InvoiceDetailTable
                      invoice={invoice}
                    />
                  </Box>
                  :
                  <InvoiceDetailed
                    invoice={invoice}
                  />
                }
              </>
          </CardContent>

          :
            <Skeleton
              variant="rounded"
              height={500}
              animation="pulse"
            />
          }

          </Card>

      <InternalNotesCard state={invoice?.internal_notes}/>
      {/*<SimpleButtons*/}
      {/*  forwardButton={'Próxima fatura'}*/}
      {/*  onForward={()=> {*/}
      {/*    invoiceList?.filter(e => e.id > params.id).length > 0*/}
      {/*    ? setRouteId(invoiceList?.filter(e => e.id > params.id)?.slice(-1)[0].id)*/}
      {/*    : setRouteId(invoiceList?.filter(e => e.id < params.id).slice(-1)[0].id)*/}
      {/*  }}*/}
      {/*/>*/}
        </Box>
      </Box>
      </>
        : filters?.modalInvoice &&
      <SimpleDialog
        open={filters?.modalInvoice}
        removeConfirm
        onClickCancel={() => {
          setFilters(prevState => ({...prevState, modalInvoice: false}))
        }}
        cancelText={'Fechar'}
        dialogContent={
          <>
              {!isLoading ?
                <>
                  <InvoiceDetailHeader
                    invoice={invoice}
                    sending={sending}
                    sendToMail={sendToMail}
                  />
                  <InvoiceDetailBody
                    condo={condo}
                    invoice={invoice}
                  />
                  {sizeMatch ?
                    <Box
                    >
                      <InvoiceDetailTable
                        invoice={invoice}
                      />
                    </Box>
                    :
                    <InvoiceDetailed
                      invoice={invoice}
                    />
                  }
                </>
                :
                <Skeleton
                  variant="rounded"
                  height={500}
                  animation="pulse"
                  sx={{
                    mb: '1.5rem'
                  }}
                />
              }
          </>
        }

      />
      }
    </>
  )
}

export default InvoiceDetail
