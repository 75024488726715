import PropTypes from 'prop-types'
import {
  AccessTimeFilledOutlined,
  CheckCircleOutlined,
  ClassOutlined, Done,
  FileCopyOutlined,
  NotificationsOutlined,
  ReceiptOutlined
} from '@mui/icons-material'
import { Avatar } from '@mui/material'
import RunningWithErrorsOutlinedIcon from "@mui/icons-material/RunningWithErrorsOutlined";
import React from "react";

const InvoiceIcon = ({ variant, styles}) => {

  function renderIcon (value) {
    if (value === 'total') {
      return [<FileCopyOutlined sx={{ color: 'secondary.main' }}/>, 'secondary.main']
    } else if (value === 'open') {
      return [<ReceiptOutlined sx={{ color: 'info.main' }}/>, 'info.main']
    } else if (value === 'pending') {
      return [<AccessTimeFilledOutlined sx={{ color: 'warning.main' }}/>, 'warning.main']
    }  else if (value === 'processing') {
        return [<RunningWithErrorsOutlinedIcon sx={{ color: 'secondary.main' }}/>, 'secondary.main']
    } else if (value === 'paid' || value === 'paid_manually') {
      return [<CheckCircleOutlined sx={{ color: 'success.main' }}/>, 'success.main']
    } else if (value === 'overdue') {
      return [<NotificationsOutlined sx={{ color: 'error.main' }}/>, 'error.main']
    } else if (value === 'exemption') {
      return [<Done sx={{ color: '#868686' }}/>, '#868686']
    } else {
      return [<ClassOutlined sx={{ color: 'secondary.light' }}/>, 'secondary.light']
    }
  }

  return (
    <Avatar key={new Date().getTime()} sx={{
      width: 36,
      height: 36,
      mr: 2,
      bgcolor: 'transparent',
      border: `.05px solid`,
      borderColor: `${renderIcon(variant)[1]}`,
      ...styles
    }}>
      {renderIcon(variant)[0]}
    </Avatar>
  )
}

InvoiceIcon.propTypes = {
  variant: PropTypes.string.isRequired
}

export default InvoiceIcon
