import {
  List,
  ListItem,
  Button,
  Box,
  Divider,
  CardContent,
  Skeleton,
  Chip,
  Alert
} from '@mui/material';
import {
  Add,
} from "@mui/icons-material";
import Masks from "../../../../components/Shared/Masks";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {api} from "../../../../services/Main/Api";
import Context from "../../../../contexts/Context";
import {getBanks} from "../../BankOperations/components/BankTransfer/Requisitions";
import {getCondoId} from "../../../../services/Main/AuthStorage";
import moment from "moment/moment";
import {useSnackbar} from "notistack";
import FormHeadText from "./FormHeadText";
import FormBodyText from "./FormBodyText";
import FormSetHead from "./FormSetHead";
import FormSetBody from "./FormSetBody";
import FormDialogs from "./FormDialogs";
import SaveCancelButton from "../../../../components/Shared/SaveCancelButton";

function DataInput(props) {
  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////

  const params = useParams()
  const {enqueueSnackbar} = useSnackbar()

  const {
    dataProvider,
    setDataProvider,
    loadingModal,
    setLoadingModal
  } = useContext(Context)

  const {
    index,
    onChange,
    onRemove,
    id,
    bondCreate,
    deleteBondById,
    getBonds,
    ...dataProps
  } = props;

  const {
    percentage,
    recipient_account,
    recipient_pix_key,
    rent_recipient,
    recipient_income_tax,
    recipient_transfer_amount
  } = dataProps

  const [handleId, setHandleId] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)
  const [bankNames, setBankNames] = useState(null)
  const [recipientNames, setRecipientNames] = useState(null)
  const [recipientOptions, setRecipientOptions] = useState(null)
  const [accOptions, setAccOptions] = useState(null)
  const [pixOptions, setPixOptions] = useState(null)
  const [bank, setBank] = useState(null)
  const [openTransfer, setOpenTransfer] = useState(false)
  const [data, setData] = useState(null)

  ///////////////////////////
  //// General functions ////
  ///////////////////////////

  function handlePercentage (event) {
    onChange(index, { ...dataProps, percentage: event.target.value, id });
  };

  function disableTransfer() {
    return data?.transferType === null && data?.transfer_date
  }

  function disableButton() {
    const shouldDisableButton =
      percentage &&
      data?.transferType &&
      (recipient_pix_key || recipient_account);

    setDataProvider(prevState => ({
      ...prevState,
      disableBondButton: !shouldDisableButton
    }));
  }

  function processCredentials () {
    const recipient = recipientOptions?.find(e => e.value === rent_recipient)
    const findCredentials = recipient?.cpfCnpj
    return findCredentials && Masks.cpfOrCnpj(findCredentials)
  }

  function returnBankLabel () {
    return accOptions?.find(e => e.value === recipient_account)?.label
  }

  function returnBankName () {
    return accOptions?.find(e => e.value === recipient_account)?.bank
  }

  function returnBankBranch () {
    return accOptions?.find(e => e.value === recipient_account)?.bankBranch
  }

  function returnBankAccount () {
    return accOptions?.find(e => e.value === recipient_account)?.account
  }

  function returnPixLabel () {
    return pixOptions?.find(e => e.value === recipient_account)?.label
  }

  function returnPixBank () {
    return pixOptions?.find(e => e.value === recipient_account)?.bank
  }

  function returnPixAcc () {
    return pixOptions?.find(e => e.value === recipient_account)?.account
  }

  function returnRecipientName(){
    return recipientOptions?.find(e => e.value === rent_recipient)?.name
  }

  function resetFilters () {
    setDataProvider(prevState => ({...prevState,  recipientId: null}))
    setHandleId(null)
  }

  function valuePreview () {
    const value = data?.contractValue
      ?.toString()
      ?.replace(/\D+/g, '')

    return Math.round(value * percentage / 100)
  }

  function formatTax() {
    return `- ${Masks?.money(recipient_income_tax?.toString()) || ''}`
  }

  function defineType() {
    if (accOptions?.find(e => e.value === recipient_account)) {
      setData(prevState => ({
        ...prevState,
        transferType: 'ted'
      }));
    }
    if (pixOptions?.find(e => e.value === recipient_account)) {
      setData(prevState => ({
        ...prevState,
        transferType: 'pix'
      }));
    }
    return null;
  }

  /////////////////////////////
  //////// Requisitions ///////
  /////////////////////////////

  //// => Getrecipient_income_tax
  function getBankName(){
    if(recipient_account) {
      api.get(`rent_recipient/account/${recipient_account}`)
        .then(response => {
          setBank(response.data.bank)
        })
    }
  }

  function getRecipientOptions () {
    api.get(`/rent_recipient/?account_id=${+getCondoId()}`)
      .then(response => {
        setRecipientOptions(response.data.results
          .map(e => (
            {
              label: `${e.name} - ${Masks.cpfOrCnpj(e.cpf_cnpj)}`,
              name: e.name,
              value: e.id,
              cpfCnpj: e.cpf_cnpj
            }))
        )
      })
      .catch(error => {
        console.log(error)
      })
  }

  function accSelect() {
    if (data?.recipientId || (rent_recipient && bankNames)) {
      api.get(`rent_recipient/account/?rent_recipient_id=${rent_recipient ?? data?.recipientId}`)
        .then(response => {
          const { results } = response.data;

          const filteredTed = results.filter(e => e.account_type === 'TED');
          const filteredPix = results.filter(e => e.account_type === 'PIX');


          const accOptions = filteredTed.map(e => ({
            label: `${e?.bank?.name} ${e.bank_account} - ${e.bank_account_digit}`,
            bank: e?.bank?.name ?? '',
            bankBranch: e.bank_branch,
            account: `${e.bank_account} - ${e.bank_account_digit}`,
            value: e.id
          }));

          const pixOptions = filteredPix.map(e => ({
            label: `${
              e?.pix_key_type === '0' ? Masks?.cpf(e?.pix_key_value?.toString() ?? '') :
                e?.pix_key_type === '1' ? Masks?.cnpj(e?.pix_key_value?.toString() ?? '') :
                  e?.pix_key_type === '3' ? Masks?.phone(e?.pix_key_value?.toString() ?? '') :
                    e?.pix_key_value?.toString() ?? ''}`,
            value: e.id,
            bank: e?.bank?.name,
            account: `${e.bank_account} - ${e.bank_account_digit}`
          }));

          setAccOptions(accOptions);
          setPixOptions(pixOptions);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  // async function accSelect() {
  //   if (data?.recipientId || rent_recipient && bankNames) {
  //     try {
  //       const response = await api.get(`rent_recipient/account/?rent_recipient_id=${rent_recipient ?? data?.recipientId}`);
  //       const { results } = response.data;
  //
  //       console.log(results)
  //       const bankAccountOptions = await Promise.all(results.map(async (e) => {
  //         return {
  //           label: `${bank?.name} ${e.bank_account} - ${e.bank_account_digit}`,
  //           bank: bank?.name ?? '',
  //           bankBranch: e.bank_branch,
  //           account: `${e.bank_account} - ${e.bank_account_digit}`,
  //           value: e.id
  //         };
  //       }));
  //
  //       setAccOptions(bankAccountOptions);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // }

  // function pixSelect () {
  //   if(data?.recipientId || rent_recipient) {
  //     api.get(`rent_recipient/pix-key/?rent_recipient_id=${rent_recipient ?? data?.recipientId}`)
  //       .then(response => {
  //         const {results} = response.data
  //         setPixOptions(results
  //           .map(e => ({
  //             label: `${
  //               e?.pix_key_type === '0' ? Masks?.cpf(e?.pix_key_value?.toString() ?? '') :
  //               e?.pix_key_type === '1' ? Masks?.cnpj(e?.pix_key_value?.toString() ?? '') :
  //               e?.pix_key_type === '3' ? Masks?.phone(e?.pix_key_value?.toString() ?? '') :
  //               e?.pix_key_value?.toString() ?? ''}`,
  //             value: e.id,
  //             bank: e?.bank?.name,
  //             account: `${e.bank_account} - ${e.bank_account_digit}`
  //           }))
  //         )
  //       })
  //       .catch(error => {
  //         console.log(error)
  //       })
  //   }
  // }

  //// => Post and Update
  function transfer () {
    setLoadingModal(true)
    const { transferType, transfer_date } = dataProvider || {}

    const dataReq = {
      transfer_date: data?.transfer_date,
      rent_contract: +params.id ?? dataProvider?.rentId,
      rent_recipient: rent_recipient
    }

    api.post(`rent_recipient/transfer/`, dataReq)
      .then(response => {
        setOpenTransfer(false)
        enqueueSnackbar('Transferência agendada', {variant: 'success'})
      })
      .catch(error => {
        enqueueSnackbar('Erro ao agendar transferência', {variant: 'error'})
        enqueueSnackbar(error.response.data[0], {variant: 'error'})
        console.log(error)
      })
      .finally(() => {
        setLoadingModal(false)
      })
  }



  //////////////////////////////////////
  //// Useffect, triggers and calls ////
  //////////////////////////////////////

  useEffect(function setRecipientAccount(){
    if (data?.accId) {
      onChange(index, {...dataProps, recipient_account: data?.accId, id,});
    }
  },[data?.accId])

  useEffect(function setRecipientPixKey(){
    if(data?.hasOwnProperty('pixId')) {
      onChange(index, {...dataProps, recipient_account: data?.pixId, id,});
    }
  },[data?.pixId])

  // useEffect(function oneTransferOption(){
  //   if (data?.transferType === 'ted'){
  //     onChange(index, {...dataProps, recipient_pix_key: null, id });
  //   }
  //   if (data?.transferType === 'pix'){
  //     onChange(index, {...dataProps, recipient_account: null, id});
  //   }
  // },[data?.transferType])

  useEffect(function setRentRecipient(){
    if (data?.recipientId) {
      onChange(index, {...dataProps, rent_recipient: data?.recipientId, id });
    }
    if (!data?.recipientId) {
      onChange(index, {...dataProps, rent_recipient: null, id});
    }
  },[data?.recipientId])

  useEffect(function setRecipient(){
    if (rent_recipient) {
      setData(prevState => ({
        ...prevState,
        recipientId: rent_recipient
      }))
    }
  }, [rent_recipient])

  useEffect(function getRecipientNames(){
    // getRecipients()
    getBanks(setBankNames)
    getRecipientOptions()
    getBankName()
  },[])

  useEffect(function whenHaveCpf (){
    accSelect()
    // pixSelect()
  },[data?.recipientId, rent_recipient, bankNames])

  useEffect(function bankNames(){
    getBanks(setBankNames)
  }, [])

  // useEffect(function removeWhenCancel(){
  //   if(dataProvider?.edit === false){
  //     onRemove(index)
  //   }
  // }, [dataProvider?.edit])
  //
  useEffect(function observeData(){
    disableButton()
  }, [data?.transferType, percentage, recipient_pix_key, recipient_account])

  useEffect(function setTransferType (){
    if (id) {
      defineType()
    }
  },[dataProvider?.edit, data?.recipientId, accOptions, pixOptions])

  function disableButton() {
    const shouldDisableButton =
      percentage &&
      data?.transferType &&
      (recipient_pix_key || recipient_account);

    setDataProvider(prevState => ({
      ...prevState,
      disableBondButton: !shouldDisableButton
    }));
  }


  const [hofLoad, setHofLoad] = useState(false)

  function waitForHof(){
      const recipientValue = recipientOptions?.find(e => e.value === rent_recipient)
      const accValue = accOptions?.find(e => e.value === recipient_account)
      const pixValue = pixOptions?.find(e => e.value === recipient_account)

      const allHofs = (accValue || pixValue)

      if (allHofs && id) {
        setHofLoad(true)
        return true
      } else {
        setHofLoad(false)
        return false
      }

  }

  useEffect(()=>{
    if(data?.transferType === 'ted' || data?.transferType === 'pix') {
      waitForHof()
      }
  },[data?.transferType])


  return (
    <>
      <Box
        sx={{
          display: 'flex',
          // borderRadius: '1rem',
          width: '100%',
          justifyContent: 'space-between',
          // mb: '1rem'
        }}
      >

        { id && !dataProvider?.edit ?

          ///////////////////////////////
          //// => Read-only content ////
          /////////////////////////////

          <Box
            sx={{
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <FormHeadText
              id={id}
              returnRecipientName={returnRecipientName}
              setHandleId={setHandleId}
              setOpenDelete={setOpenDelete}
              setOpenTransfer={setOpenTransfer}
            />

            <FormBodyText
              data={data}
              returnRecipientName={returnRecipientName}
              setHandleId={setHandleId}
              setOpenDelete={setOpenDelete}
              processCredentials={processCredentials}
              percentage={percentage}
              returnBankLabel={returnBankLabel}
              returnPixLabel={returnPixLabel}
              setOpenTransfer={setOpenTransfer}
              recipient_pix_key={recipient_pix_key}
              recipient_account={recipient_account}
            />
          </Box>

          : dataProvider?.edit ?

            //////////////////////////////////////
            //// => FormSet - Create & Update ////
            //////////////////////////////////////

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                // maxWidth: '700px',
                // paddingX: '2rem'
              }}
            >

              <FormSetHead
                id={id}
                returnRecipientName={returnRecipientName}
                bondCreate={bondCreate}
                data={data}
                disableButton={disableButton}
                onRemove={onRemove}
                resetFilters={resetFilters}
                index={index}
                params={params}
                setOpenTransfer={setOpenTransfer}
              />

              <FormSetBody
                id={id}
                data={data}
                setData={setData}
                recipientOptions={recipientOptions}
                rent_recipient={rent_recipient}
                percentage={percentage}
                handleId={handleId}
                handlePercentage={handlePercentage}
                accOptions={accOptions}
                pixOptions={pixOptions}
                recipient_account={recipient_account}
                recipient_pix_key={recipient_pix_key}
              />
            </Box>
            : !id &&
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                // maxWidth: '700px',
                // paddingX: '2rem'
              }}
            >

              <FormSetHead
                id={id}
                returnRecipientName={returnRecipientName}
                bondCreate={bondCreate}
                data={data}
                disableButton={disableButton}
                onRemove={onRemove}
                resetFilters={resetFilters}
                index={index}
                params={params}
                setOpenTransfer={setOpenTransfer}
              />

              <FormSetBody
                id={id}
                data={data}
                setData={setData}
                recipientOptions={recipientOptions}
                rent_recipient={rent_recipient}
                percentage={percentage}
                handleId={handleId}
                handlePercentage={handlePercentage}
                accOptions={accOptions}
                pixOptions={pixOptions}
                recipient_account={recipient_account}
                recipient_pix_key={recipient_pix_key}
              />
            </Box>


        }
        <FormDialogs
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
          deleteBondById={deleteBondById}
          handleId={handleId}
          openTransfer={openTransfer}
          setOpenTransfer={setOpenTransfer}
          recipient_account={recipient_account}
          loadingModal={loadingModal}
          data={data}
          setData={setData}
          percentage={percentage}
          recipient_transfer_amount={recipient_transfer_amount}
          recipient_pix_key={recipient_pix_key}
          disableTransfer={disableTransfer}
          transfer={transfer}
          processCredentials={processCredentials}
          formatTax={formatTax}
          returnBankLabel={returnBankLabel}
          returnBankName={returnBankName}
          returnBankBranch={returnBankBranch}
          returnBankAccount={returnBankAccount}
          returnPixLabel={returnPixLabel}
          returnPixBank={returnPixBank}
          returnPixAcc={returnPixAcc}
          returnRecipientName={returnRecipientName}
        />


      </Box>
    </>
  );
}

function RentRecipientSet(props) {
  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////
  const { data, onChange, setBondDelete, isLoading, setIsLoading, bondCreate, getBonds, deleteBondById } = props;
  const { dataProvider, setDataProvider } = useContext(Context)
  const { edit } = dataProvider || {}
  const { enqueueSnackbar } = useSnackbar()

  ///////////////////////////
  //// General functions ////
  ///////////////////////////

  function handleDataChange (index, newData) {
    const newDataList = [...data];
    newDataList[index] = newData;
    onChange(newDataList);
  }

  function handleDataRemove (index) {
    const newDataList = data.filter((_, i) => i !== index);
    const newDataDelete = data.filter((_, i) => i === index);
    const objToDelete = newDataDelete.find((obj) => obj.hasOwnProperty("id"));
    if (objToDelete) {
      setBondDelete(prevState => [...prevState, objToDelete]);
    }
    onChange(newDataList);
  }

  function handleAddData () {
    const newDataList = [
      ...data, {
        percentage: '',
        rent_contract: '',
        recipient_account: '',
        recipient_pix_key: ''
      }
    ];
    onChange(newDataList);
  }

  function handleEdit () {
    setDataProvider(prevState => ({
      ...prevState,
      edit: !edit
    }))
  }

  function verifyPercentageSum(array) {
    let soma = 0;
    for (let i = 0; i < array.length; i++) {
      soma += +array[i].percentage;
    }
    return soma;
  }

  useEffect(function disableSelection(){
    setDataProvider(prevState => ({
      ...prevState,
      recipients: data?.map(e => e.rent_recipient)
    }))
  },[data])

  useEffect(function informDataLenght(){
    setDataProvider(prevState => ({
      ...prevState,
      dataBondLength: data?.length
    }))
  }, [data])


  // function reactDeleteBond() {
  //     handleEdit()
  //     setDataProvider(prevState => ({...prevState, afterDelete: true}))
  //     enqueueSnackbar('Por favor refaça a distribuição', {
  //       variant: 'info',
  //       anchorOrigin: {
  //         vertical: 'bottom',
  //         horizontal: 'center'
  //       }
  //     });
  //   }

  // useEffect(function mapObserver(){
  //   if (data?.length > 0) {
  //     reactDeleteBond()
  //   }
  // }, [dataProvider?.deleteBondDone])

  useEffect(function percentWarning(){
    if (verifyPercentageSum(data) !== 100) {
      setDataProvider(prevState => ({
        ...prevState,
        errorPercent: true
      }))
    } else {
      setDataProvider(prevState => ({
        ...prevState,
        errorPercent: false
      }))
    }
  },[data])

  // useEffect(function loadWithoutPercent(){
  //   if (verifyPercentageSum(data) !== 100 && isLoading === false) {
  //     // handleEdit()
  //     setDataProvider(prevState => ({...prevState, afterDelete: true}))
  //     enqueueSnackbar('Por favor refaça a distribuição', {
  //       variant: 'error',
  //       anchorOrigin: {
  //         vertical: 'bottom',
  //         horizontal: 'center'
  //       }
  //     })
  //   }
  //
  // },[isLoading])


  return (
    <>
      {!isLoading ?
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                // paddingX: '2rem',
                width: '100%',
              }}
            >
              {data?.length > 0 &&
                <Alert
                  variant={'outlined'}
                  severity="info"
                  sx={{
                    mb: '1rem',
                    boxShadow: 'none',
                    border: 'none',
                    p: 0
                  }}
                >
                  {`Para "SALVAR" é necessário que o valor de "Distribuição" chegue a 100% (soma das porcentagens)`}
                </Alert>
              }
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                // paddingX: '2rem',
                mb: '1rem'
              }}
            >

              {data?.length > 0 &&
                // <Typography
                //   sx={{
                //     fontSize: '20px',
                //     fontWeight: 'bold',
                //     ,
                //     color: verifyPercentageSum(data) === 100 ? 'success.main' : 'error.main'
                //   }}
                // >
                //   Distribuição: {verifyPercentageSum(data)} %
                // </Typography>
                <Chip
                  label={`Distribuição: ${verifyPercentageSum(data)} %`}
                  sx={{
                    borderColor: verifyPercentageSum(data) === 100 ? 'success.main' : 'error.main',
                    color: verifyPercentageSum(data) === 100 ? 'success.main' : 'error.main',
                    fontWeight: 'bold',
                  }}
                  variant="outlined"
                />

              }
              {
                data?.length > 0 && !edit && data?.some(e => e.id) &&
                <Button
                  onClick={handleEdit}
                >
                  Editar beneficiários
                </Button>
              }

            <SaveCancelButton
              edit={edit}
              disabled={(verifyPercentageSum(data) !== 100) || (dataProvider?.disableBondButton === true)}
              saveClick={() => {
                bondCreate(true)
                handleEdit()
              }}
              cancelClick={() => {
                handleEdit()
                getBonds(null, true)
                // onChange(data?.filter(item => item.id !== undefined))
              }}

            />

            {/*{edit &&*/}
            {/*  <Box>*/}
            {/*    <Button*/}
            {/*      variant={'contained'}*/}
            {/*      disabled={(verifyPercentageSum(data) !== 100) || (dataProvider?.disableBondButton === true)}*/}
            {/*      onClick={() => {*/}
            {/*        bondCreate(true)*/}
            {/*        handleEdit()*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Salvar*/}
            {/*    </Button>*/}
            {/*    <Button*/}
            {/*      sx={{*/}
            {/*        ml: 'auto'*/}
            {/*      }}*/}
            {/*      onClick={() => {*/}
            {/*        handleEdit()*/}
            {/*        getBonds(null, true)*/}
            {/*        // onChange(data?.filter(item => item.id !== undefined))*/}
            {/*      }}*/}
            {/*      disabled={(dataProvider?.disableBondButton === true) && dataProvider?.afterDelete === true}*/}
            {/*    >*/}
            {/*      Cancelar*/}
            {/*    </Button>*/}

            {/*  </Box>*/}
            {/*}*/}
            </Box>
            {/*<Divider*/}
            {/*  sx={{*/}
            {/*    mt: '0.5rem',*/}
            {/*    mb: '1rem'*/}
            {/*  }}*/}
            {/*/>*/}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <List>
                {data?.map((entry, index) => (
                  <ListItem key={index} disableGutters>
                    <DataInput
                      index={index}
                      id={entry.id}
                      rent_contract={entry.rent_contract}
                      recipient_pix_key={entry.recipient_pix_key}
                      rent_recipient={entry.rent_recipient}
                      percentage={entry.percentage}
                      recipient_account={entry.recipient_account}
                      recipient_income_tax={entry.recipient_income_tax}
                      recipient_transfer_amount={entry.recipient_transfer_amount}
                      onChange={handleDataChange}
                      onRemove={handleDataRemove}
                      deleteBondById={deleteBondById}
                      bondCreate={bondCreate}
                      getBonds={getBonds}
                      setIsLoading={setIsLoading}
                    />
                  </ListItem>
                ))}
              </List>
              <Button
                size={'small'}
                variant="contained"
                startIcon={<Add/>}
                onClick={() => {
                  handleAddData()
                  setDataProvider(prevState => ({
                    ...prevState,
                    edit: true
                  }))
                }}
                sx={{
                  mt: '1rem'
                }}
                // disabled={dataProvider?.edit}
              >
                Adicionar
              </Button>
            </Box>
          </CardContent>
        :
        <Skeleton
          height={300}
          width={'100%'}
          variant='rounded'
        />
      }

    </>
  );
}

export default RentRecipientSet