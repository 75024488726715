import { Box, palette, styled } from '@mui/system'
import { Card, CardContent, CardHeader, Dialog, Divider, Grid, Paper, Skeleton, Typography } from '@mui/material'
import moment from 'moment'
import { getCondoId, getFilterDate, setFilterDate } from '../../../services/Main/AuthStorage'
import { useContext, useEffect, useState } from 'react'
import Context from '../../../contexts/Context'
import {
  ArticleOutlined,
  CurrencyExchange,
  DescriptionOutlined,
  PaidOutlined,
  PaymentsOutlined, PendingActionsOutlined,
  ReceiptOutlined, RequestQuoteOutlined
} from '@mui/icons-material'
import { axisClasses } from '@mui/x-charts/ChartsAxis'
import { api } from '../../../services/Main/Api'
import { useSearchParams } from 'react-router-dom'
import Masks from '../../../components/Shared/Masks'
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import OverduesCard from '../../../components/Shared/OverduesCard'
import Treemap from '../../../components/Charts/Treemap'
import * as React from 'react'
import Brasil from '../../../components/Shared/Brasil'








const AdminDashboard = () => {

  const storageDate = getFilterDate()

  const { condoIdLogin } = useContext(Context)
  const [date, setDate] = useState(storageDate ? moment(new Date(storageDate)).format() : moment().format())
  const condo = getCondoId()

  const [result, setResult] = useState({})
  const [loadingResult, setLoadingResult] = useState(false)
  const [resultSixMonthsValue, setResultSixMonthsValue] = useState([])
  const [resultSixMonths, setResultSixMonths] = useState([])
  const [billingCompany, setBillingCompany] = useState({
    xAxis: [],
    ySeries: []
  })
  const [invoiceCount, setInvoiceCount] = useState({
    valueMax: 0,
    value: 0,
    percentage: 0
  })

  const [transferCount, setTransferCount] = useState({
    valueMax: 0,
    value: 0,
    percentage: 0
  })
  const [searchParams, setSearchParams] = useSearchParams();

  function getData(){

    let filter = {}
    setLoadingResult(true)
    api.get(`analytics/rent/`, {params: filter})
      .then(response => {
        const data = response.data

        setResult(data)
        setLoadingResult(false)
        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  function getSixMonthsRentData(){

    let filter = {}

    api.get(`analytics/month-rent-contract/`, {params: filter})
      .then(response => {
        const data = response.data

        setResultSixMonths(data.months)
        setResultSixMonthsValue(data.values)
        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  function getInvoiceAndTransfer(){

    let filter = {}

    api.get(`analytics/invoice-and-transfer/`, {params: filter})
      .then(response => {
        const data = response.data
        setInvoiceCount({
          valueMax: data.invoice_value_max,
          value: data.invoice_value,

        })

        setTransferCount({
          valueMax: data.transfer_value_max,
          value: data.transfer_value,

        })

        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  function getBillingCompany(){

    let filter = {}

    api.get(`analytics/billing-company/`, {params: filter})
      .then(response => {
        const data = response.data
        setBillingCompany({
          xAxis: data.months,
          ySeries: data.values,
        })



        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  useEffect(()=>{
    getData()
    getSixMonthsRentData()
    getInvoiceAndTransfer()
    getBillingCompany()
  }, [])

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));


  return (
    <>

      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item xs={12} md={4} lg={4}>
          <Item >
            <PaidOutlined sx={{ color:'primary.main', flexGrow:1  }} />
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36', marginBottom: "1rem" }}> Faturas</Typography>

            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                <Skeleton variant="rectangular" width={200} height={50} />
                :
                <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                  {result?.total_customer_invoice && Masks.money(result?.total_customer_invoice.toString())}
                </Typography>
              }

            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Item >
            <ReceiptOutlined sx={{color:'primary.main', flexGrow:1  }} />
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36', marginBottom: "1rem" }}>Faturas pagas</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                <Skeleton variant="rectangular" width={200} height={50} />
                :
                <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }}>
                  {result?.total_customer_invoice_paid && Masks.money(result?.total_customer_invoice_paid.toString())}
                </Typography>
              }

            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Item >
            <CurrencyExchange sx={{ color:'primary.main', flexGrow:1  }} />
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Número de Clientes</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                <Skeleton variant="rectangular" width={200} height={50} />
                :
                <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                  {result?.customer_invoice_number && result?.customer_invoice_number}
                </Typography>
              }
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Item sx={{justifyContent: 'center', display: "flex"}}>
          <Brasil  />
          </Item>
        </Grid>
        <Grid item  xs={12} md={6} lg={8}>

        </Grid>



        <Grid item xs={12}>
          <Divider sx={{my:3, color: 'gray.main' }}>Locação</Divider>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Item >
            <PaidOutlined sx={{ color:'primary.main', flexGrow:1  }} />
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36', marginBottom: "1rem" }}> Faturamento bruto</Typography>

            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                <Skeleton variant="rectangular" width={200} height={50} />
                :
                <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                  {result?.total_rent && Masks.money(result?.total_rent.toString())}
                </Typography>
              }

            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Item >
            <ReceiptOutlined sx={{color:'primary.main', flexGrow:1  }} />
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36', marginBottom: "1rem" }}> Faturas</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                <Skeleton variant="rectangular" width={200} height={50} />
                :
              <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }}>
                {result?.total_invoice &&  Masks.money(result?.total_invoice.toString())}
              </Typography>
              }

            </Box>
          </Item>
        </Grid>
        <Grid item xs={12}md={4} lg={3}>
          <Item >
            <CurrencyExchange sx={{ color:'primary.main', flexGrow:1  }} />
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}> Repasses</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                <Skeleton variant="rectangular" width={200} height={50} />
                :
              <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                {result?.total_transfer && Masks.money(result?.total_transfer.toString())}
              </Typography>
              }
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <Item >
            <PaymentsOutlined sx={{ color:'primary.main', flexGrow:1  }} />
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36',   marginBottom: "1rem" }}>
                Encargos locatícios</Typography>

            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                <Skeleton variant="rectangular" width={200} height={50} />
                :
              <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                {result?.total_payment && Masks.money(result?.total_payment.toString())}
              </Typography>
              }
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Item >
            <ArticleOutlined sx={{color: 'primary.main'}}/>
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}> Número de Contratos</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                <Skeleton variant="rectangular" width={200} height={50} />
                :
              <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                {result?.contract_number}
              </Typography>
              }
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Item >
            <DescriptionOutlined sx={{color: 'primary.main'}}/>
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>
                Número de Faturas</Typography>

            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                <Skeleton variant="rectangular" width={200} height={50} />
                :
              <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }}>
                {result?.invoice_number}
              </Typography>
              }
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Item >
            <PendingActionsOutlined sx={{color: 'primary.main'}}/>
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36',   marginBottom: "1rem" }}> Número de Repasses</Typography>

            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                <Skeleton variant="rectangular" width={200} height={50} />
                :
              <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                {result?.transfer_number}
              </Typography>
              }
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Item >
            <RequestQuoteOutlined sx={{color: 'primary.main'}}/>
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36',   marginBottom: "1rem" }}> Número de Encargos Locatícios</Typography>

            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                <Skeleton variant="rectangular" width={200} height={50} />
                :
              <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                {result?.payment_number}
              </Typography>
              }
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={12} lg={8}>
          <Card>
            <CardHeader title={'Faturamento dos últimos 6 meses'}/>

            <CardContent>
          <LineChart
            xAxis={[{
              data: billingCompany.xAxis,
              scaleType: 'point',
              valueFormatter: (value) => (moment(value, 'MM/YYYY').format('MMM/YYYY')),
            }]}
            margin={{ top: 5, right: 30, bottom: 30, left: 40 }}
            colors={['#f50863']}
            series={[
              {
                data: billingCompany.ySeries,
                valueFormatter: (value) => (Masks.money(value.toString())),
                area: true,
              },
            ]}
            yAxis={[
              {
                valueFormatter: (value) => (`${value / 100000}k`),
              },
            ]}

            height={500}
          />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>

          <Card>
            <CardHeader title={'Número de novos contratos'}/>
            <CardContent>
            <BarChart
              sx={{display: "block"}}
              colors={['#f50863']}
              xAxis={[
                {
                  id: 'barCategories',
                  data: resultSixMonths,
                  scaleType: 'band',

                },
              ]}
              series={[
                {
                  data: resultSixMonthsValue,
                },
              ]}

              height={500}
            />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={6} lg={4}>

          <Card>
            <CardHeader title={'Faturas'}/>
            <Divider/>
            <CardContent>
            <Gauge
              value={invoiceCount.value}
              valueMax={invoiceCount.valueMax}
              startAngle={-110}
              endAngle={110}
              height={250}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 30,
                  transform: 'translate(0px, 0px)',
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: '#f50863',
                },
              }}
              text={
                ({ value, valueMax }) => `${value} / ${valueMax}`
              }
            />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={6} lg={4}>
          <Card>
            <CardHeader title={'Repasses'}/>
            <Divider />
            <CardContent>
            <Gauge
              value={transferCount.value}
              valueMax={transferCount.valueMax}
              startAngle={-110}
              endAngle={110}
              height={250}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 30,
                  transform: 'translate(0px, 0px)',
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: '#f50863',
                },
              }}
              text={
                ({ value, valueMax }) => `${value} / ${valueMax}`
              }
            />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>

          </Grid>
        <Grid item xs={12}>
          {/*<Card >*/}
          {/*  <CardContent>*/}
          {/*    /!*<Treemap date={date}/>*!/*/}
          {/*  </CardContent>*/}
          {/*</Card>*/}
        </Grid>
      </Grid>


    </>)
}

export default AdminDashboard