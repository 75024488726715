import {
  Alert,
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip
} from "@mui/material";
import {DescriptionOutlined, ForwardToInboxOutlined, NoteAdd, Pending} from "@mui/icons-material";
import React, {useContext, useState} from "react";
import SimpleDialog from "../../../../../../../components/Shared/SimpleDialog";
import moment from "moment/moment";
import {DatePicker} from "@mui/x-date-pickers";
import Context from "../../../../../../../contexts/Context";
import {api} from "../../../../../../../services/Main/Api";
import {useSnackbar} from "notistack";

const InvoiceDetailGenerateSlip = (props) => {
  const { invoice } = props
  const { dataProvider, setDataProvider, loadingModal, setLoadingModal } = useContext(Context)
  const { invoiceDueDate, refreshSlip } = dataProvider || {}
  const {enqueueSnackbar} = useSnackbar()


  const [openDialog, setOpenDialog] = useState(false)
  const dateFormat =  moment(invoiceDueDate).format('YYYY-MM-DD')
  const [reqData, setReqData] = useState({ due_date: dateFormat})
  const [requesting, setRequesting] = useState(false)

  function createSlip () {
    setLoadingModal(true)

    api.post(`bank-slip/invoice/${invoice?.id}/`, reqData)
      .then(() => {
        enqueueSnackbar("Pedido para gerar boleto efetuado!", {variant: 'success'})
        setDataProvider(prevState => ({...prevState, refreshSlip: !refreshSlip}))
        setOpenDialog(false)
      })
      .catch(error => {
        console.log(error)
        enqueueSnackbar("Erro ao gerar boleto", {variant: "error"})
      })
      .finally(() => {
        setLoadingModal(false)
      })
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingModal}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
      <Tooltip
        title={ 'Gerar Boleto' }
        arrow
        placement={'top'}
      >
        <span>
          <IconButton
            disabled={invoice?.status?.key !== 'open'}
            target={'_blank'}
            onClick={() => setOpenDialog(true)}
          >
            <NoteAdd/>
          </IconButton>
        </span>
      </Tooltip>
      <SimpleDialog
        title={'Gerar boleto'}
        open={openDialog}
        setOpen={setOpenDialog}
        onClickConfirm={() => createSlip()}
        dialogContent={
        <>
          <Alert
            severity={'info'}
            sx={{
              background: 'transparent',
              boxShadow: 'none',
              mt: '1rem',
              padding: 0
            }}
          >
            {'Depois de gerar o boleto, não será possível fazer alterações nesta fatura.'}
          </Alert>
            <FormControlLabel
              sx={{
                mb: '1rem',
                paddingX: '0.5rem'
              }}
              control={
                <Checkbox
                  onChange={(event) => {
                    setReqData(prevState => ({...prevState, send_email: event.target.checked}))
                  }}
                />
              }
              label="Enviar para o e-mail?"/>
          <DatePicker
            sx={{ textAlign: 'center' }}
            views={['year', 'month', 'day']}
            label="Data de vencimento"
            minDate={moment().add(1, 'day')}
            value={moment(reqData?.due_date)}
            onChange={(newValue) => setReqData((prevState) => ({
              ...prevState,
              due_date: moment(newValue).format('YYYY-MM-DD')
            }))}
            renderInput={
              (params) => (
                <TextField
                  {...params}
                  size={'small'}
                  sx={{ mb: 2 }}
                  helperText={null}
                  fullWidth
                />
              )
            }
          />

        </>

        }
      />


    </>
  )
}

export default InvoiceDetailGenerateSlip