import {
  Avatar,
  Box, Button,
  Card,
  CardHeader,
  Divider,
  Fade,
  Grid,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from "@mui/material";
import {People, Update} from "@mui/icons-material";
import RentRecipienSet from "./RentRecipientSet/RentRecipienSet";
import React, {useContext, useState} from "react";
import Context from "../../../contexts/Context";
import RecipientTransferHistory from "./RecipientTransferHistory/RecipientTransferHistory";
import AutoTransfer from "./RentRecipientSet/AutoTransfer";
import RentPreview from "./RentRecipientSet/RentPreview";

const RecipientTransferCard = (props) => {
  const {
    dataBond,
    setDataBond,
    isLoading,
    setBondDelete,
    deleteBondById,
    bondCreate,
    getBonds,
    bondUpdate,
    setIsLoading,
    reqData,
    setReqData,
    error,
    targetRef,
    getContract,
    prevStateData

  } = props || {}
  const { dataProvider } = useContext(Context)

  const [tabValue, setTabValue] = useState(0)

  return(
    <>
      <Grid
        item
        xs={12}
        ref={targetRef}

      >
      {!isLoading ?
        <Card>
          <CardHeader

            // avatar={<Avatar sx={{bgcolor: 'primary.main'}}><People/></Avatar>}
            title={
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  // alignItems: 'center'
                  flexDirection: 'column'

                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: '1rem'
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: 'primary.main',
                      mr: '1rem'
                    }}
                  >
                    <People/>
                  </Avatar>

                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                    }}
                  >
                    Beneficiarios
                  </Typography>

                </Box>
              </Box>
            }

          />

          <Divider sx={{mb: '.3rem'}}/>


          {tabValue === 0 ?
            <Fade
              in={true}
              mountOnEnter
              unmountOnExit
              timeout={300}
            >
              <Box>
                <RentRecipienSet
                  data={dataBond}
                  onChange={setDataBond}
                  isLoading={isLoading}
                  setBondDelete={setBondDelete}
                  deleteBondById={deleteBondById}
                  bondCreate={bondCreate}
                  getBonds={getBonds}
                  bondUpdate={bondUpdate}
                  setIsLoading={setIsLoading}
                />
              </Box>
            </Fade>
            : tabValue === 1 &&
            <Fade
              in={true}
              mountOnEnter
              unmountOnExit
              timeout={300}
            >
              <Box>
                <RecipientTransferHistory />
              </Box>
            </Fade>
          }
        </Card>
        :
        <Skeleton
          height={600}
          width={'100%'}
          variant='rounded'
        />
      }

      </Grid>



    </>
  )
}

export default RecipientTransferCard