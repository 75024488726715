import React, {
  useContext,
  useEffect,
  useState
} from "react";
import {
  Avatar,
  Box,
  Fade,
  Link,
  IconButton,
  Typography
} from "@mui/material";
import {
  Done,
  ExpandMore,
  FileDownloadOutlined,
  NorthOutlined,
  ReceiptOutlined
} from "@mui/icons-material";
import SimpleTypography from "../../../../../components/Shared/SimpleTypography";
import Context from "../../../../../contexts/Context";

const CardStatement = ({
    e,
    hasData,
  }) => {

  const { filters } = useContext(Context)
  const [open, setOpen] = useState(true);

  function getLabel(entry) {
    const details = entry.Details?.toString();
    const entryValue = entry.EntryValue?.toString();

    switch (true) {
      case details?.includes('Boleto Pago'):
        return 'Pagamento recebido';
      case details?.includes('Pagamentos de conta'):
        return 'Pagamento efetuado';
      case details?.includes('Saldo Inicial'):
        return 'Saldo Inicial';
      case details?.includes('Saldo Final'):
        return 'Saldo Total';
      case entryValue?.includes('-'):
        return 'Transferência enviada';
      default:
        return 'Transferência recebida';
    }
  }

  useEffect(function(){
    if (!hasData){
      filters?.expand ? setOpen(false) : setOpen(true)
    }
  },[filters?.expand])


  return (
    <>
      <IconButton
          onClick={()=>setOpen(prevState => !prevState)}
          sx={{
            mb: '0.5rem'
          }}
      >
        <ExpandMore
            sx={{
              transform: open && 'rotate(180deg)',
              color: open ? '#A2A2A2FF' : 'primary.main'
            }}
        />
      </IconButton>
        <>
          {
            e.entries.map((e, index) =>
            <Fade
              key={index}
              in={open}
              mountOnEnter
              unmountOnExit
              timeout={300}
            >
              <Box
                key={index}
                sx={{
                  width: '100%',
                  mb: '2rem',

                }}
              >
                <Box

                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'center',
                    mb: '1rem',
                    width: '100%'
                  }}
                >
                  <Avatar

                    sx={{
                      bgcolor: 'transparent',
                      border: `.05px solid`,
                      borderColor: e.EntryValue?.toString().includes('-') ? '#A2A2A2FF' : '#4bc454',
                      width: '40px',
                      height: '40px',
                      mr: '1rem',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    {
                      e.Details?.toString()?.includes('Boleto Pago')
                        ? <ReceiptOutlined
                          sx={{
                            color: '#4bc454',
                          }}
                        />
                        : e.Details?.toString()?.includes('Pagamentos de conta')
                          ? <Done
                            sx={{
                              color: '#A2A2A2FF'
                            }}
                          />
                          : <NorthOutlined
                            sx={{
                              color: e.EntryValue?.toString().includes('-') ? '#A2A2A2FF' : '#4bc454',
                              transform: !e.EntryValue?.toString().includes('-') && 'rotate(180deg)',
                            }}
                          />
                    }

                  </Avatar>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'start',
                      alignItems: 'start',
                      width: '100%'

                    }}
                  >

                    <SimpleTypography
                      label={
                        getLabel(e)
                      }
                      labelStyles={{
                        fontWeight: 'bold'
                      }}
                      type={'moneyMultiplier'}
                      state={e}
                      keyName={'EntryValue'}
                      boxStyles={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: 16,
                        mb: '0.5rem',
                      }}
                    />
                    <Typography>
                      { e?.Tags && e?.Tags.includes("contract_fee") ?
                          e?.Tags[1] :
                          e?.Details?.replace('Transferária', '')
                      }
                    </Typography>


                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',

                      }}
                      >
                      <SimpleTypography
                        state={e}
                        keyName={'EntryDate'}
                        type={'time'}
                        boxStyles={{mb: '1rem'}}
                      />

                      {e?.ReceiptUrl &&
                        <Link
                          target={"_blank"}
                          underline="none"
                          href={e?.ReceiptUrl}

                        >
                          <FileDownloadOutlined
                            sx={{
                              // fontSize: '30px'
                            }}
                          />

                        </Link>
                      }
                    </Box>

                  </Box>
                </Box>
              </Box>
            </Fade >
            )
          }
        </>
    </>
  )
}

export default CardStatement