import React, { useState } from "react";
import {
  Avatar, Backdrop, Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  CircularProgress,
  Skeleton,
  TextField,
} from "@mui/material";
import { Print } from "@mui/icons-material";
import { api } from "../../../../../services/Main/Api";
import { getCondoId } from "../../../../../services/Main/AuthStorage";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

const BankStatementDownload = ({ isLoading }) => {
  const [data, setData] = useState(moment().format())
  const [requesting, setRequesting] = useState(false)

  async function handleSubmit() {
    setRequesting(true);

    const formattedData = {
      month: moment(data).format('M'),
      year: moment(data).format('YYYY')
    };

    try {
      const response = await api.post(`fitbank/print/entry/${getCondoId()}/`, formattedData);
      window.open(window.location.href = response.data.url, '_blank');
    } catch (error) {
      console.log(error);
    } finally {
      setRequesting(false);
    }
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={requesting}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
        {!isLoading ?
          <Card
            sx={{
              width: '100%',
            }}
          >
            <CardHeader
              title={'Baixar  extrato'}
              avatar={<Avatar sx={{bgcolor: "primary.main"}}><Print/></Avatar>}
            />
            <Divider />
              <CardContent
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}

              >
                <DatePicker
                  openTo="month"
                  views={['year', 'month',]}
                  value={moment(data).format()}
                  clearLabel
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ readOnly: true }}
                  onChange={(newValue) => {
                    setData(moment(new Date(newValue)).format())
                  }}

                  renderInput={(params) =>
                    <TextField
                      variant={'standard'}
                      size="small"
                      sx={{
                        maxWidth: '150px',
                        width: '100%',
                        mb: '1rem'
                      }}
                      {...params}
                    />}
                />

                <Button
                  variant={'text'}
                  onClick={() => handleSubmit()}
                >
                  Baixar Arquivo
                </Button>
              </CardContent>

          </Card>
          :
          <Skeleton
            variant="rounded"
            animation="pulse"
            height={272.833}
            sx={{
              width: '100%'
            }}
          />
        }
    </>

  )
}

export default BankStatementDownload