import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Button,
  Typography,
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText, List, Divider, Link
} from '@mui/material'
import UserMenu from '../../../components/Shared/UserMenu'
import React, {useContext, useState} from 'react'
import BreadCrumbs from '../BreadCrumbs'
import {
  AccountCircle,
  AccountCircleOutlined,
  ArrowBack,
  ArticleOutlined,
  Business, CodeOutlined, DataObject,
  MenuOutlined,
  Speed
} from '@mui/icons-material'
import NotificationMenu from '../../../components/Shared/NotificationMenu'
import BalanceBar from '../../../components/Bank/BalanceBar'
import SelectedCondo from "../../../components/Shared/SelectedCondo";
import Context from "../../../contexts/Context";
import Drawer from '@mui/material/Drawer'
import ListItemButton from '@mui/material/ListItemButton'
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ReceiptIcon from '@mui/icons-material/Receipt'
import { styled } from '@mui/material/styles'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import logo from '../../../assets/images/blocob3-logo.png'
import logoText from '../../../assets/images/blocob3-text.png'
import UserList from '../../../pages/MainPages/Admin/UserList'
const drawerWidth = 0

const AdminHeader = ({ handleDrawerToggle }) => {
  const { user } = useContext(Context)
  const { perm_bank } = user || {}
  const [open, setOpen] = React.useState(false)
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const [req, setReq] = useState(null)
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '2rem 0',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
    spacing: 2
  }));

  return (
    <Box sx={{ mb: 10 }}>

      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <DrawerHeader>

                <img src={logo} alt="" style={{ height: 50, marginRight: 10  }}/>
                <img src={logoText} alt="" style={{ height: 24,  }}/>

          </DrawerHeader>
          <Divider />
          <List>
            <ListItem color={'secondary'}
                      component={'a'} href={'/admin/dashboard'} key={'Dashboard'} disablePadding>
              <ListItemButton >
                <ListItemIcon>
                  <Speed />
                </ListItemIcon>
                  <ListItemText  primary={'Dashboard'} sx={{color: 'secondary.main'}} />
              </ListItemButton>
            </ListItem>

              <ListItem component={'a'} href={'/admin/invoice'} key={'Faturas'} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ReceiptIcon />
                  </ListItemIcon>
                    <ListItemText primary={'Faturas'} sx={{color: 'secondary.main'}}  />
                </ListItemButton>
              </ListItem>

            <ListItem component={'a'} href={'/admin/rent-contract'}   key={'contratos'} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ArticleOutlined/>
                </ListItemIcon>
                <ListItemText primary={'Contratos'} sx={{color: 'secondary.main'}} />
              </ListItemButton>
            </ListItem>
            <ListItem component={'a'} href={'/admin/company'}   key={'empresas'} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Business />
                </ListItemIcon>
                  <ListItemText primary={'Empresas'} sx={{color: 'secondary.main'}} />
              </ListItemButton>
            </ListItem>

            <ListItem component={'a'} href={'/admin/user'}   key={'usuarios'} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <AccountCircleOutlined />
                </ListItemIcon>
                <ListItemText primary={'Usuários'} sx={{color: 'secondary.main'}} />
              </ListItemButton>
            </ListItem>

            <ListItem component={'a'} href={'/admin/webhooks'}   key={'webhooks'} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DataObject />
                </ListItemIcon>
                <ListItemText primary={'Webhooks'} sx={{color: 'secondary.main'}} />
              </ListItemButton>
            </ListItem>


          </List>
          <Divider />

          <List>
            <ListItem color={'secondary'}
                      component={'a'} href={'/'} key={'Dashboard'} disablePadding>
              <ListItemButton >
                <ListItemIcon>
                  <ArrowBack />
                </ListItemIcon>
                <ListItemText  primary={'Área do Cliente'} sx={{color: 'secondary.main'}} />
              </ListItemButton>
            </ListItem>
          </List>

        </Box>
      </Drawer>
      <AppBar
        position={'fixed'}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          bgcolor: '#1E3040',
          boxShadow: 'rgb(32 32 32 / 6%) 0px 8px 16px;',
        }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <IconButton
            color="white"
            aria-label="Abrir Barra"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuOutlined/>
          </IconButton>
          <Box>
            <IconButton color={'neutral'}  onClick={toggleDrawer(true)} aria-label="menu" size="large">
              <MenuOutlined />
            </IconButton>

          </Box>
          <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
            <Typography
              variant={'h5'}
              sx={{
                color: 'rgb(31, 48, 64)',
                mr: '1rem',
                fontSize: '1rem'
              }}>

              <Chip label={'Área Administrativa'} variant={'outlined'} color={'neutral'} ></Chip></Typography>
            { perm_bank === true &&
              <Box
                onClick={() => setReq(prevState => !prevState)}
                >
                {/*<BalanceBar color={'neutral'} req={req}/>*/}
              </Box>
            }

            <UserMenu/>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
export default AdminHeader
