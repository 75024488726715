import { Avatar, Box, CardHeader, Chip, Divider, Tooltip, Typography } from '@mui/material'
import {Article} from "@mui/icons-material";
import React from "react";
import {useParams} from "react-router-dom";
import moment from 'moment'

const CardHeaderContract = (
  {
    reqData,
    statusFilter,
    statusColor,
  }
) => {

  const params = useParams()

  return(
    <>
      <CardHeader
        avatar={<Avatar
          sx={{ bgcolor: 'primary.main' }}>
          <Article/></Avatar>}
        title={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                Contrato
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                }}
              >
               <small>{`BB3-${reqData?.id}`}</small>
              </Typography>
            </Box>
            <Box>
              <Chip
                label={reqData?.prepaid ? 'Pré-pago' : 'Pós-Pago'}
                color={'primary'}
                variant="outlined"
                size={'small'}
                sx={{marginRight: 1}}
              />

              {params?.id && reqData?.status &&
                <Tooltip title={`Contrato criado em ${moment(reqData?.created_at).format("D [de] MMMM [de] YYYY")}`} >
                  <Chip
                    label={statusFilter(reqData?.status)}
                    color={statusColor(reqData?.status)}
                    variant="outlined"
                    size={'small'}
                  />
                </Tooltip>
              }
            </Box>

          </Box>
        }
      />
      <Divider sx={{ mb: '.3rem' }}/>
    </>
  )
}

export default CardHeaderContract