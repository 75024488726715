import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import React from "react";
import moment from "moment/moment";
import {Badge} from "@mui/material";

const ContractRead = (
  {
    reqData,
    adminTaxPreview,
    intermediationPreview
  }) => {

  function contractType(param) {
    switch (param) {
      case 'commercial':
        return 'Comercial';
      case 'residencial':
        return 'Residencial';
      default:
        return '- - -';
    }
  }
    function readjustmentIndexType(param) {
        switch (param) {
            case 'IGPM':
                return 'IGP-M';
            case 'IPCA':
                return 'IPC-A';
            default:
                return 'Não Selecionado';
        }
    }

  return (
    <>
      <SimpleTypography
        label={'Data de Início'}
        directValue={moment(reqData?.start_date).format('DD/MM/YYYY')}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '1rem'
        }}
      />

      <SimpleTypography
        label={'Tipo de Contrato'}
        directValue={contractType(reqData?.contract_type)}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '1rem'
        }}
      />

      <SimpleTypography
        label={'Tempo de Contrato'}
        directValue={`${reqData?.contract_time} meses`}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '1rem'
        }}
      />



      <SimpleTypography
        label={`Taxa Administrativa ${reqData?.administrative_tax?.toString().replace('.' , ',') ?? ''}%`}
        directValue={`${adminTaxPreview()}  `}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '1rem'
        }}
      />

      <SimpleTypography
        label={`Taxa de Intermediação ${reqData?.intermediation_fee?.toString().replace('.' , ',') ?? ''} %`}
        directValue={`${intermediationPreview()}  `}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '1rem'
        }}
      />
        <SimpleTypography
            badge={true}
            label={'Indice para Reajuste'}
            directValue={readjustmentIndexType(reqData?.readjustment_index)}
            labelStyles={{
                fontWeight: 'bold'
            }}
            boxStyles={{
                width: '100%',
                mb: '1rem'
            }}
        />


        <SimpleTypography
        label={'Valor'}
        directValue={reqData?.value}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '1rem'
        }}
      />

    </>
  )
}

export default ContractRead