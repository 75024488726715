import {
  Box, Button,
  Card,
  CardContent, Dialog, DialogActions, DialogContent, DialogTitle,
  Divider,
  Fade, FormControl,
  Grid,
  IconButton, InputLabel, MenuItem, OutlinedInput, Select,
  Skeleton,
  Typography,
  useMediaQuery
} from '@mui/material'
import { Edit } from "@mui/icons-material";
import Masks from "../../../../components/Shared/Masks";
import React, {useContext, useEffect, useState} from "react";
import Context from "../../../../contexts/Context";
import {useParams} from "react-router-dom";
import UnitFormModelModal from "../../Unit/UnitFormModelModal";
import {api} from "../../../../services/Main/Api";
import {useSnackbar} from "notistack";
import ContractInputs from "./ContractInputs";
import ContractRead from "./ContractRead";
import UnitSearch from "./UnitSearch";
import CardHeaderContract from "./CardHeaderContract";
import RenterInfo from "./RenterInfo";
import SimpleDialog from "../../../../components/Shared/SimpleDialog";
import UnitForm from "../../Unit/UnitForm";
import SaveCancelButton from "../../../../components/Shared/SaveCancelButton";
import RentContractFee from '../RentContractFee'
import RentContractForm from '../RentContractForm'

const ContractForm = (props) => {
  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////

  const { getUnitOptions, setFilters, filters, setDataProvider, dataProvider, screenRefresh, setScreenRefresh, refreshScreen, setRefreshScreen } = useContext(Context)
  const { isLoading, setIsLoading, reqData, setReqData, unitOptions, error, setError, contractLoad, prevStateData, getContract, update} = props || {}
  const params = useParams()
  const { id } = params
  const { contract_time, contract_type } = reqData || {}
  const { activeStep, unitInfo } = dataProvider || {}

  const {enqueueSnackbar} = useSnackbar()
  const sizeMatch = useMediaQuery('@media (min-width:730px)')

  const [edit, setEdit] = useState(false)

  ///////////////////////////
  //// General functions ////
  ///////////////////////////

  const contractTypeOptions = [
    {
      value: 'commercial', label: 'Comercial'
    },
    {
      value: 'residencial', label: 'Residencial'
    }
  ]

  const contractReadjustmentIndex = [
    {
      value: null, label: '- - -'
    },
    {
      value: 'IGPM', label: 'IGP-M'
    },
    {
      value: 'IPCA', label: 'IPC-A'
    }
  ]

  function adminTaxPreview() {
    const value = reqData?.administrative_tax_amount;

    if (isNaN(Number(value))) {
      return value;
    } else {
      const finalValue = reqData?.administrative_tax_amount ;
      return Masks?.money(finalValue?.toString()) || '';
    }
  }

  function intermediationPreview () {
    const value = reqData?.value
      ?.toString()
      ?.replace(/\D+/g, '')

    const finalValue = Math.round(value * reqData?.intermediation_fee / 100)
    return Masks?.money(finalValue?.toString()) || ''
  }

  /////////////////////////////
  //////// Requisitions ///////
  /////////////////////////////

  //// => Get

  function verifyUnit () {
    if (!id && reqData?.unit) {
      api.get(`/rent_contract/`, {params: {unit_id: reqData?.unit}})
        .then(response => {
          response.data.count > 0 && enqueueSnackbar('Imóvel com contrato existente. Por favor, escolha outro', {
            variant: 'info',
            position: 'top-center',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }})

        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  function statusFilter(param) {
    switch (param) {
      case 'active':
        return 'Ativo';
      case 'inactivating':
        return 'Encerrando';
      case 'inactive':
        return 'Encerrado';
      case 'pending':
        return 'Pendente';
      default:
        return '- - -';
    }
  }

  function statusColor(param) {
    switch (param) {
      case 'active':
        return 'success';
      case 'inactivating':
        return 'secondary';
      case 'inactive':
        return 'error';
      case 'pending':
        return 'warning';
      default:
        return 'black';
    }
  }

  //////////////////////////////////////
  //// Useffect, triggers and calls ////
  //////////////////////////////////////

  useEffect(function whenSelectUnit () {
    verifyUnit()
  }, [reqData?.unit])


  useEffect(() => {
    const resolution = reqData?.unit && unitOptions?.find(e => e.value === reqData?.unit);

    if (dataProvider?.reqDone === false && resolution?.value > 0) {
      setIsLoading(false);
    }
  }, [dataProvider?.reqDone, reqData?.unit, unitOptions]);

  useEffect(() => {
    if (dataProvider?.reqDone === true) {
      setIsLoading(true);
      setReqData(prevState => ({
        ...prevState,
        unit: dataProvider?.unitId,
      }));
      setDataProvider(prevState => ({
        ...prevState,
        reqDone: false,
      }));
    }
  }, [dataProvider?.unitId]);

  // useEffect(function setDefaultContract() {
  //   if (!contract_time) {
  //     const contractTime = contract_type === 'residencial' ?? 30
  //     setReqData(prevState => ({...prevState, contract_time: contractTime}));
  //   } {
  //     const newContractTime = contract_type === 'residencial' ? 30 : 60;
  //     setReqData(prevState => ({...prevState, contract_time: newContractTime}));
  //   }
  // }, [contract_type]);
  //
  useEffect(function firstLoad (){
    getUnitOptions()
  }, [])

  useEffect(function resetAutoTransferInputs(){
    if (reqData?.auto_transfer === false) {
      setReqData(prevState => ({
        ...prevState,
        transfer_day: null,
        transfer_guaranteed: null,
        transfer_guaranteed_duration: null,
      }))
    }
  },[reqData?.auto_transfer])

  useEffect(function cleanErrors() {
    if (reqData?.value && error?.length > 0) {
      const { value, ...newData } = error;
      props?.setError(newData);
    }
    if (reqData?.administrative_tax && error?.length> 0) {
      const { administrative_tax, ...newData } = error;
      props?.setError(newData);
    }
  }, [reqData?.value, reqData?.administrative_tax]);


  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  useEffect(function observeInputs() {
      const requiredKeys = ['unit', 'start_date', 'contract_type', 'contract_time', 'readjustment_index', 'value', 'administrative_tax', 'status', 'intermediation_fee'];
      const hasChanged = requiredKeys.some(key => reqData?.[key]?.toString() !== prevStateData?.[key]?.toString())
    if(hasChanged) {
      setIsSaveEnabled(true)
    } else {
      setIsSaveEnabled(false)
    }

  }, [contractLoad, reqData]);

  return (
    <Grid item xs={12}>
    {!isLoading ?
        <Card
          sx={{
            width: '100%',
          }}
        >
          <CardHeaderContract
            reqData={reqData}
            statusFilter={statusFilter}
            statusColor={statusColor}
          />

         <UnitSearch
           reqData={reqData}
           setReqData={setReqData}
           unitOptions={unitOptions}
           setFilters={setFilters}
           error={error}
         />

          <CardContent
            sx={{
              display: 'flex',
              flexDirection: sizeMatch ? 'row' : 'column',
              justifyContent: 'space-between'
            }}
          >
                <Fade
                  in={!!reqData?.unit}
                  mountOnEnter
                  unmountOnExit
                  timeout={300}
                >


                  <Box
                    sx={{
                      justifyContent: 'space-between',
                      // p: '1rem',
                      borderRadius: '1rem',
                      width: sizeMatch ? '49%' : '100%',
                    }}
                  >

                    {!id &&
                      //// => Title in create
                      <>
                        <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '18px',
                           color: 'primary.main'
                          }}
                        >
                          Contrato
                        </Typography>
                        <Divider
                          sx={{
                            mt: '0.7rem',
                            mb: '1rem'
                          }}
                        />
                      </>
                      ////
                    }


                    {id &&
                      //// => Title and buttons in update
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: '0.5rem'
                        }}
                      >
                        <Typography
                          sx={{
                            // ...(edit && {mb: '1rem'}),
                            fontWeight: 'bold',
                            fontSize: '18px',
                            color: 'primary.main'
                          }}>
                          Contrato
                        </Typography>
                        {!edit &&
                          <IconButton
                            sx={{
                              minHeight: '49.154px',
                              display:'flex',
                              alignItems: 'center',
                              paddingX: 0

                            }}
                            onClick={() => setEdit(true)}
                          >
                            <Edit/>
                          </IconButton>
                        }

                        {/*{edit &&*/}
                        {/*  <Box*/}
                        {/*    sx={{*/}
                        {/*      minHeight: '49.154px',*/}
                        {/*      display:'flex',*/}
                        {/*      alignItems: 'center'*/}
                        {/*    }}*/}
                        {/*  >*/}
                        {/*    <Button*/}
                        {/*      size={'small'}*/}
                        {/*      disabled={!isSaveEnabled}*/}
                        {/*      variant={'contained'}*/}
                        {/*      onClick={() => {*/}
                        {/*        update()*/}
                        {/*        setEdit(false)*/}
                        {/*      }}*/}
                        {/*    >*/}
                        {/*      Salvar*/}
                        {/*    </Button>*/}
                        {/*    <Button*/}
                        {/*      size={'small'}*/}
                        {/*      onClick={() => {*/}
                        {/*        getContract(null, true)*/}
                        {/*        setEdit(false)*/}
                        {/*      }}*/}
                        {/*    >*/}
                        {/*      Cancelar*/}
                        {/*    </Button>*/}
                        {/*  </Box>*/}
                        {/*}*/}
                        <SaveCancelButton
                          edit={edit}
                          disabled={!isSaveEnabled}
                          saveClick={() => {
                            update()
                            setEdit(false)
                            setScreenRefresh(true);
                          }}
                          cancelClick={() => {
                            getContract(null, true)
                            setEdit(false)
                          }}
                        />


                      </Box>
                    }

                    {edit || !id ?
                      <>

                        <ContractInputs
                          reqData={reqData}
                          setReqData={setReqData}
                          contractTypeOptions={contractTypeOptions}
                          contractReadjustmentIndex={contractReadjustmentIndex}
                          adminTaxPreview={adminTaxPreview}
                          intermediationPreview={intermediationPreview}
                          error={error}

                        />

                      </>
                      : id &&
                      <ContractRead
                        reqData={reqData}
                        adminTaxPreview={adminTaxPreview}
                        intermediationPreview={intermediationPreview}
                      />
                      ////
                    }

                  </Box>
                </Fade>
            <RenterInfo
              reqData={reqData}
              setFilters={setFilters}
              unitInfo={unitInfo}
              isLoading={isLoading}
            />
          </CardContent>
        </Card>
        :
        <Skeleton
          height={400}
          width={'100%'}
          variant='rounded'
        />
      }
      <SimpleDialog
        // title={reqData?.id ? 'Editar imóvel' : 'Registrar imóvel'}
        open={dataProvider?.modalUnit}
        removeConfirm
        onClickCancel={() => {
          setDataProvider(prevState => ({...prevState, modalUnit: false}))
          // resetFilter()
        }}
        cancelText={'Cancelar'}
        dialogContent={
          <UnitForm
            getContract={getContract}
            setUnitData={setReqData}
          />

        }
      />
      {/*<UnitFormModelModal*/}
      {/*  setUnitInfo={setUnitInfo}*/}
      {/*  getContract={getContract}*/}
      {/*/>*/}

    </Grid>
  )
}

export default ContractForm